<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container">
      <!-- <div class="row mt-5">
        <div class="col-md-12 text-center">
          <ol class="breadcrumb" id="customBreadcrumb" :class="$style.customBreadCrumb"></ol>
          <hr>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-md-4 mt-3" 
        v-for="item in currentData.filter(x => x.parent === currentParent)" 
        :key="item.id">
          <div class="row" :class="$style.customCard" @click="setCurrentParent(item.id, item.name)" :title="item.name"
          v-if="!item.notShowUsernames.includes(userDetails.username)">
            <div class="col-md-6 justify-content-center align-self-center">
              <img :src="`../../../static/img/${item.cover}`"
              alt="Cover not found"
              width="100"
              :class="$style.coverImg"
              onerror="this.src='../../../static/img/no-cover.png'">
            </div>
            <div class="col-md-6 justify-content-center align-self-center">
              <h1 :class="$style.contentTitle">{{item.name}}</h1>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row" v-if="currentParent === 0">
        <div class="col-md-4 mt-5">
          <div class="row" :class="$style.customCard" @click="setCurrentParent('youtube')">
            <div class="col-md-6 justify-content-center align-self-center">
              <img :src="'../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px">
            </div>
            <div class="col-md-6 justify-content-center align-self-center">
              <h1 :class="$style.contentTitle">How To Use Digital Platforms</h1>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4 mt-5">
          <div class="row" :class="$style.customCard" @click="setCurrentParent('aim')">
            <div class="col-md-6 justify-content-center align-self-center">
              <img :src="'../../../static/img/ms.png'"  :class="$style.coverImg" style="width: 100px">
            </div>
            <div class="col-md-6 justify-content-center align-self-center">
              <h1 :class="$style.contentTitle">Monitoring Sheets</h1>
            </div>
          </div>
        </div> -->
        <div class="col-md-4 mt-5" v-for="(parent, i) in resources.filter(x => x.package_parent_id === 4183)" :key="i">
          <div class="row" :class="$style.customCard" @click="setCurrentParent(parent.package_id, parent.package_name)">
            <div class="col-md-6 justify-content-center align-self-center">
              <img :src="parent.package_image ? parent.package_image : '../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px">
            </div>
            <div class="col-md-6 justify-content-center align-self-center">
              <h1 :class="$style.contentTitle">{{parent.package_name}}</h1>
            </div>
          </div>
        </div>
      </div>
      <template v-else-if="currentParent === 999">
        <div class="row">
          <button type="button" @click="setCurrentPage" class="col-md-2 mt-5 ml-4 btn" style="background-color: #012866; color: white;">Back</button>
        </div>
        <div class="row" >
          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="FNL English" @click="openYoutubeModal(1)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube1.jpg'"  :class="$style.coverImg" style="width: 300px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">FNL English</h1>
              </div>
            </div>
          </div> -->

          <div class="col-md-4 mt-3">
            <a href="https://www.dropbox.com/s/be448dco05egdck/FNLENGLISH.mp4?dl=1">  
            <div class="row" :class="$style.customCard" title="FNL English">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube1.jpg'"  :class="$style.coverImg" style="width: 300px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">FNL English</h1>
              </div>
            </div>
            </a>
          </div>
          <div class="col-md-4 mt-3">
            <a href="https://www.dropbox.com/s/kdnutd2tgvs1z8t/GO%20FURTHER%20RLP.mp4?dl=1">  
            <div class="row" :class="$style.customCard" title="RLP: GO FURTHER">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/video-file.png'"  :class="$style.coverImg" style="width: 300px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">RLP: GO FURTHER</h1>
              </div>
            </div>
            </a>
          </div>
          <div class="col-md-4 mt-3">
            <a href="https://www.dropbox.com/s/0nk0ozlzkkata90/KEEP IT REAL RLP.mp4?dl=1">  
            <div class="row" :class="$style.customCard" title="RLP: KEEP IT REAL">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/video-file.png'"  :class="$style.coverImg" style="width: 300px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">RLP: KEEP IT REAL</h1>
              </div>
            </div>
            </a>
          </div>
          <div class="col-md-4 mt-3">
            <a href="https://www.dropbox.com/s/czeg3m49jimsnpz/Odyssey RLP.mp4?dl=1">  
            <div class="row" :class="$style.customCard" title="RLP: ODYSSEY">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/video-file.png'"  :class="$style.coverImg" style="width: 300px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">RLP: ODYSSEY</h1>
              </div>
            </div>
            </a>
          </div>

          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="FNL Report" @click="openYoutubeModal(2)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube2.jpg'"  :class="$style.coverImg" style="width: 300px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">FNL Report</h1>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="Hexagonia" @click="openYoutubeModal(3)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube3.jpg'"  :class="$style.coverImg" style="width: 300px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">Hexagonia</h1>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="Karnevall" @click="openYoutubeModal(4)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube4.jpg'"  :class="$style.coverImg" style="width: 300px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">Karnevall</h1>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="Kidzwonder - 1" @click="openYoutubeModal(5)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube5.jpg'"  :class="$style.coverImg" style="width: 300px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">Kidzwonder 1-2</h1>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-4 mt-3">
            <div class="row" :class="$style.customCard" title="Kidzwonder - 2" @click="openYoutubeModal(6)"
            data-toggle="modal" data-target="#exampleModalCenter">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/youtube6.jpg'"  :class="$style.coverImg" style="width: 300px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">Kidzwonder 3-4</h1>
              </div>
            </div>
          </div> -->
        </div>
      </template>
      <template v-else-if="currentParent === 444">
        <div class="row">
          <button type="button" @click="setCurrentPage" class="col-md-2 mt-5 ml-4 btn" style="background-color: #012866; color: white;">Back</button>
        </div>
        <div class="row" >
          <div class="col-md-4 mt-3" v-for="(x,i) in unique" :key="i" @click="goToAim(x)">
            <div class="row" :class="$style.customCard" :title="x">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="'../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px;">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">Grade: {{x}}</h1>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <button type="button" @click="setCurrentPage" class="col-md-2 mt-5 ml-4 btn" style="background-color: #012866; color: white;">Back</button>
        </div>
        <div class="row" >
          <div class="col-md-4 mt-3" v-for="(child, i) in childData" :key="i">
            <div class="row" :class="$style.customCard" :title="child.link_name" @click="openLink(child)">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="child.thumbnail_url ? child.thumbnail_url : '../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">{{child.link_name}}</h1>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row mt-4" v-if="currentParent === 33">
        <div class="col-md-12">
          <div :class="$style.divExplain">
            <code :class="$style.customCode">
              .iso uzantılı dosyaları Windows 10 kurulu bilgisayarlarda açmak için dosyaya çift tıklamanız ya da sağ tıklayarak bağla seçeneğini seçmeniz yeterli olacaktır. 
            Daha alt Windows versiyonlarında ise .iso uzantılı dosyaları açmak için dosyaya sağ tıklayarak <b>Winrar ile Aç</b> seçeneğini seçmeniz
            yeterli olacaktır. Bilgisayarınızda <b>Winrar</b> kurulu değil ise <a href="https://www.win-rar.com/postdownload.html?&L=5" target="_blank"><u>buradan</u></a> indirebilirsiniz.
          </code>
          </div>
        </div>
      </div>
      <!-- <div class="row mt-2" v-if="currentParent === 33">
        <div class="col-md-12">
          <div :class="$style.divExplain">
            <code :class="$style.customCode">
            .air uzantılı Sure serisini açmak için bilgisayarınızda <b>Adobe AIR</b> yüklü ise çift tıklamanız yeterli olacaktır. Eğer <b>Adobe AIR</b> yüklü değil ise 
            <a href="https://get.adobe.com/air/download/?installer=Adobe_AIR_31.0_for_Win32&stype=7696&standalone=1" target="_blank"><u>buradan</u></a> indirebilirsiniz.
          </code>
          </div>
        </div>
      </div> -->
      <div class="modal fade" id="exampleModalCenter" tabindex="-1"
      role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" :src="youtubeLink" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import swal from 'sweetalert';
import { mapState } from 'vuex';
import HeaderCustom from '@/components/HeaderCustom';
import { colors } from '@/config';
import data from '../../../static/js/teacherResources';
import service from '../../services/content';
import schoolsArr from '../../../static/js/newSchools';

export default {
  name: 'resources',
  data() {
    return {
      currentData: data,
      currentParent: 0,
      resources: [],
      childData: [],
      youtubeLink: '',
      title: '',
      unique: null,
      school: null,
      campus: null,
    };
  },
  components: {
    HeaderCustom,
  },
  computed: {
    ...mapState(['userDetails']),
  },
  beforeMount() {
    this.getPackages();
  },
  updated() {
    tippy('[title]', {
      placement: 'top',
    });
  },
  methods: {
    setCurrentPage() {
      this.currentParent = 0;
    },
    openYoutubeModal(id) {
      if (id === 1) {
        this.youtubeLink = 'https://www.youtube.com/embed/I-p7cwMmEHY';
        this.title = 'FNL English';
      } else if (id === 2) {
        this.youtubeLink = 'https://www.youtube.com/embed/QnMzHzz7YdQ';
        this.title = 'FNL Report';
      } else if (id === 3) {
        this.youtubeLink = 'https://www.youtube.com/embed/xi65fqUscIg';
        this.title = 'Hexagonia';
      } else if (id === 4) {
        this.youtubeLink = 'https://www.youtube.com/embed/mHbolxKvqaw';
        this.title = 'Karnevall';
      } else if (id === 5) {
        this.youtubeLink = 'https://www.youtube.com/embed/_5GE1L7hsEY';
        this.title = 'Kidzwonder Çağ 1';
      } else if (id === 6) {
        this.youtubeLink = 'https://www.youtube.com/embed/bUWy8HS636E';
        this.title = 'Kidzwonder Çağ 2';
      }
    },
    goToAim(grade) {
      let code = null;
      if (this.school) {
        const aimData = this.school.aim_codes.find(x => x.grade === Number(grade));
        if (aimData.query === 'grade') {
          const codeData = aimData.codes.find(x => x.title === String(grade));
          if (codeData) {
            code = codeData.code;
          }
        }
        const studentWthsCode = `http://wths.uespublishing.com/login.html?code=${btoa(code)}`;
        window.open(studentWthsCode, '_blank');
      }
    },
    openLink(item) {
      window.open(item.link_url, '_blank');
    },
    setCurrentParent(id) {
      if (id === 'youtube') {
        this.currentParent = 999;
      } else if (id === 'aim') {
        this.campus = this.userDetails.organization.find(x => x.type === 'campus');
        this.school = schoolsArr.find(x => x.school_id === this.campus.id);
        if (this.school) {
          const organization = this.userDetails.organization.filter(x => x.type === 'grade');
          const grade = [];
          // eslint-disable-next-line
          for (const x of organization) {
            grade.push(x.name.split('-')[0]);
          }
          this.unique = grade.filter((v, i, a) => a.indexOf(v) === i);
          this.aimActive = true;
          this.currentParent = 444;
        } else {
          swal('Error!', 'Bir Problem Yaşıyoruz En Kısa Sürede Düzelteceğiz.', 'error');
        }
      } else {
        const subs = this.resources.find(x => x.package_id === id);
        this.childData = subs.package_links;
        this.currentParent = id;
      }
      // const subs = this.currentData.filter(x => x.parent === id);
      // if (subs.length > 0) {
      //   this.currentParent = id;
      //   const item = this.currentData.find(x => x.id === id);
      //   if (item) {
      //     this.setBreadcrumbData(item.id);
      //   }
      // } else {
      //   const item = this.currentData.find(x => x.id === id);
      //   // item.link = '';
      //   // item.fileName = '';
      //   if (item.fileName) {
      //     window.open(`/static/files/${item.fileName}`);
      //   } else if (item.link) {
      //     window.open(item.link, '_blank');
      //   } else {
      //     swal('Resource Not Found', '', 'warning');
      //   }
      // }
    },
    async getPackages() {
      // eslint-disable-next-line
      const data = await service.getLink();
      // console.log(data);
      this.resources = data.data.data.filter(x => x.package_type === 'link');
    },
    setBreadcrumbData(currentId) {
      const customBreadcrumb = document.getElementById('customBreadcrumb');
      customBreadcrumb.innerHTML = '';
      let bcElement = null;
      bcElement = document.createElement('a');
      bcElement.className = 'breadcrumb-item';
      bcElement.style.color = colors.primaryColor;
      // bcElement.style.fontWeight = 'bold';
      bcElement.innerText = 'Teachers\' Resources';
      bcElement.setAttribute('href', '/resources');
      customBreadcrumb.appendChild(bcElement);
      const elementArr = [];
      const item = this.currentData.find(x => x.id === currentId);
      elementArr.push(item);
      let parentExist = true;
      let parentId = item.parent;
      while (parentExist) {
        const parent = this.currentData.find(x => x.id === parentId); // eslint-disable-line
        if (parent) {
          elementArr.unshift(parent);
          parentId = parent.parent;
        } else {
          parentExist = false;
        }
      }
      for (let i = 0; i < elementArr.length; i += 1) {
        const element = elementArr[i];
        if (i !== elementArr.length - 1) {
          bcElement = document.createElement('a');
          bcElement.className = 'breadcrumb-item';
          bcElement.style.color = colors.primaryColor;
          bcElement.innerText = element.name;
          bcElement.setAttribute('href', `/resources/${element.id}`);
          customBreadcrumb.appendChild(bcElement);
        } else {
          bcElement = document.createElement('li');
          bcElement.className = 'breadcrumb-item';
          bcElement.style.fontWeight = 'bold';
          bcElement.style.color = colors.primaryColor;
          bcElement.innerText = item.name;
          customBreadcrumb.appendChild(bcElement);
        }
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

