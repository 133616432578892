<template>
  <div class="card" :class="$style.profile">
    <div class="row" :class="$style.divProfile">
      <div class="col-md-12 col-sm-12 col-xs-12" @mouseout="showImg" @mouseover="hideImg">
        
        <img :src="imgpath" alt="Profile Image" :class="$style.img" ref="profilePicture">
        <div class="fa fa-plus-circle fa-4x" aria-hidden="true" :class="$style.uploadButton" ref="uploadBtn">
            <input :class="$style.fileUpload" data-toggle="modal" data-target="#exampleModalCenter21">
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 mt-2" :class="$style.contentTitleDiv">
          <h1 :class="$style.contentTitle">{{profileData.first_name}} {{profileData.last_name}}</h1>
      </div>
        <div class="col-md-12 col-sm-12 col-xs-12 mt-2" :class="$style.customTag">
            <span :class="$style.secondTag" class="badge badge-light">{{profileData.schoolName}}</span>
          </div>
          <div class="col-md-12 col-xs-12" :class="$style.customTag">
            <span :class="$style.secondTag" class="badge badge-light">{{profileData.branchName}}</span>
          </div>
          <div class="col-md-12 col-xs-12" :class="$style.customTag">
            <span :class="$style.secondTagGrade" class="badge badge-light">{{profileData.gradeName}}</span>
          </div>
    </div>
      <div class="modal fade" id="exampleModalCenter21" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Avatars</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6 col-md-3" v-for="(item) in modalData().srcLength" :key="item">
                  <img :id="`img${item}`" @click="setImageBorder(item)" style="border:2px solid #efefef; cursor:pointer" class="card-img-top mr-2 mb-2" :src="`static/img/avatars/${modalData().level}/${item}.jpg`">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" @click="saveAvatars()" data-dismiss="modal" class="btn btn-success">Save changes</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import userService from '@/services/user';
// import defaultAvatar from '../../../static/img/default-avatar.png';

export default {
  name: 'profile',
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    ...mapGetters(['profileData']),
    ...mapState(['userDetails']),
    imgpath() {
      const avatar = this.profileData.avatar
        ? this.profileData.avatar // : 'https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png';
        : '/static/img/default-avatar.png';
      return avatar;
    },
  },
  methods: {
    hideImg() {
      this.$refs.profilePicture.style.opacity = 0.5;
      this.$refs.uploadBtn.style.display = 'block';
      // this.$refs.uploadBtn.style.cursor = 'pointer';
    },
    showImg() {
      this.$refs.profilePicture.style.opacity = 1;
      this.$refs.uploadBtn.style.display = 'none';
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.upload(this.selectedFile);
    },
    async upload(data) {
      const formData = new FormData();
      formData.append('avatar', data);
      const isPictureUpload = await userService.setProfilePicture(formData);
      // console.log('deneme', isPictureUpload);
      if (isPictureUpload) {
        this.$store.dispatch('getUserDetails');
      }
    },
    setImageBorder(id) {
      for (let index = 1; index <= this.modalData().srcLength; index += 1) {
        document.getElementById(`img${index}`).style.borderColor = '#efefef';
      }
      document.getElementById(`img${id}`).style.borderColor = 'red';
      this.lastSelect = id;
    },
    modalData() {
      const data = {};
      if (this.userDetails) {
        const branch = this.userDetails.organization.find(x => x.type === 'level').name;
        // console.log(branch);
        // eslint-disable-next-line
        data.level = branch === 'Lise' ? 3 : branch === 'Ortaokul' ? 2 : 1;
        // eslint-disable-next-line
        data.srcLength = data.level === 1 ? 15 : data.level === 2 ? 12 : 16;
        return data;
      }
      return data;
    },
    async saveAvatars() {
      this.ready = false;
      const path = `${this.modalData().level}/${this.lastSelect}`;
      const isPictureUpload = await userService.setProfilePicture(path);
      if (isPictureUpload) {
        this.$store.dispatch('getUserDetails');
        this.ready = true;
      } else {
        this.ready = true;
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>
