<template>
  <div :class="$style.divHelpDesk" :style="styleObj" class="d-flex justify-content-center align-items-center">
    <i v-if="!isHeader" class="fas fa-headset fa-2x" :class="$style.icon"></i>
    <i v-else class="fas fa-headset" :class="$style.iconHeader"></i>
    <span :class="$style.txtPhone" :style="styleObj" v-if="!isHeader">
      HELP DESK: 0850 550 50 90
    </span>
    <span :class="$style.txtPhoneHeader" :style="styleObj" v-else>
      0850 550 50 90
    </span>
  </div>
</template>

<script>
  export default {
    name: 'helpDesk',
    props: ['bgcolor', 'txtcolor', 'isHeader'],
    data() {
      return {
        styleObj: {
          backgroundColor: this.bgcolor,
          color: '#00247e',
        },
      };
    },
  };

</script>

<style src='./style.less' lang='less' module/>
