const schoolsArr = [
  {
    school_name: 'Adana Final Akademi',
    school_id: 4030,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Adana Final Akademi Çukurova VIP Ortaokulu',
    school_id: 9845,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Adana Final Okulları',
    school_id: 556,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Adana Mavi Göl Final Akademi Anadolu ve Fen Lisesi',
    school_id: 4010,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Akşehir Final Okulları',
    school_id: 635,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ALANYA FİNAL AKADEMİ VE ANADOLU LİSESİ',
    school_id: 4607,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Batıkent Final Okulları',
    school_id: 1067,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Cebeci Final Akademi Anadolu Lisesi',
    school_id: 1223,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Elvankent Final Okulları',
    school_id: 1494,
    aim_codes: [
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Eryaman Final Okulları',
    school_id: 1514,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ANKARA ETLİK FİNAL AKADEMİ',
    school_id: 4483,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Etimesgut Final Akademi Ortaokulu - Anadolu ve Fen Lisesi',
    school_id: 4483,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ANKARA GÖLBAŞI FİNAL AKADEMİ',
    school_id: 4495,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ANKARA GÜNEŞEVLER FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 4508,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ANKARA KEÇİÖREN BAĞCI FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 4263,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Keçiören Final Akademi Anadolu Lisesi',
    school_id: 9992,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Keçiören Final Okulları',
    school_id: 655,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Mustafa Kemal Final Akademi Anadolu Lisesi',
    school_id: 4263,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Oran Final Okulları',
    school_id: 655,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Park Avenue Twin Final Okulları',
    school_id: 2169,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Sincan Final Akademi Anadolu Lisesi',
    school_id: 5776,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Yenimahalle Final Akademi Anadolu Lisesi',
    school_id: 4433,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Yıldız Final Akademi Anadolu ve Fen Lisesi',
    school_id: 7962,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Çayyolu Final Okulları',
    school_id: 1276,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Şentepe Final Akademi Anadolu Lisesi',
    school_id: 4327,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Şentepe Final Akademi Ortaokulu',
    school_id: 4294,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ANTALYA FİNAL AKADEMİ VE ANADOLU LİSESİ',
    school_id: 4585,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Antalya Final Okulları',
    school_id: 685,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Antalya Kemer Final Okulları',
    school_id: 739,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Avcılar Final Okulları',
    school_id: 742,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'AYDIN FİNAL AKADEMİ AND LİSESİ',
    school_id: 4667,
    aim_codes: [
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    school_name: 'AYDIN SÖKE FİNAL OKULLARI',
    school_id: 4637,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Bakırköy Final Anadolu Lisesi',
    school_id: 9390,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Balıkesir Bandırma Final Akademi Ortaokul ve Anadolu Lisesi',
    school_id: 4718,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Batman Final Okulları',
    school_id: 1099,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Başakşehir Final Okulları',
    school_id: 1051,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'BODRUM FİNAL AKADEMİ',
    school_id: 5132,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'BOLU FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 4762,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Bursa Final Okulları',
    school_id: 1185,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Bursa Görükle Final Okulları',
    school_id: 1211,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Bursa Nilüfer Final Akademi Anadolu Lisesi',
    school_id: 4856,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Bursa Çekirge Final Akademi Anadolu Lisesi',
    school_id: 4881,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ceyhan Final Okulları',
    school_id: 1231,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Cizre Final Akademi Anadolu ve Fen Lisesi',
    school_id: 5237,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Cizre Final Okulları',
    school_id: 1252,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Darıca Final Okulları',
    school_id: 1377,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Denizli Final Okulları',
    school_id: 1399,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Denizli Merkezefendi Final Akademi Anadolu Lisesi',
    school_id: 6039,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Dörtyol Final Akademi Anadolu Lisesi',
    school_id: 4062,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Düzce Final Okulları',
    school_id: 4183,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Diyarbakır Final Okulu',
    school_id: 6173,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    school_name: 'KEŞAN FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 4186,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Elazığ Final Akademi Ortaokulu - Lise',
    school_id: 4292,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Elazığ Final Okulları',
    school_id: 2017,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Elbistan Final Akademi Anadolu Lisesi',
    school_id: 4608,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ergani Final Akademi Anadolu Lisesi',
    school_id: 4726,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Erzurum Yakutiye Final Akademi Anadolu Lisesi',
    school_id: 4909,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Erzurum Final Okulları',
    school_id: 1527,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Erzincan Final Akademi Anadolu Lisesi',
    school_id: 4788,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Eskişehir Final Akademi',
    school_id: 4951,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Gaziantep Final Akademi Anadolu ve Fen Lisesi',
    school_id: 1624,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Gaziantep Nizip Final Okulları',
    school_id: 1632,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Antalya Gazipaşa Final Akademi Anadolu Lisesi',
    school_id: 4572,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kocaeli Gebze Final Akademi Anadolu ve Fen Lisesi',
    school_id: 9500,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'GEBZE FİNAL OKULU',
    school_id: 10152,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Gümüşhane Final Akademi',
    school_id: 9506,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Isparta Final Akademi Anadolu ve Fen Lisesi',
    school_id: 5154,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'K.MARAŞ ON İKİ ŞUBAT FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 5964,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Karabük Final Okulları',
    school_id: 1743,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ereğli Final Akademi Anadolu Lisesi',
    school_id: 4708,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Zonguldak Final Akademi Anadolu Lisesi',
    school_id: 6154,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Zonguldak Karadeniz Ereğli Final Okulu',
    school_id: 6155,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kars Final Akademi Okulları',
    school_id: 5998,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kastamonu Final Akademi Anadolu Lisesi',
    school_id: 6029,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kayseri Final Okulları',
    school_id: 1796,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kemer Final Akademi Anadolu Lisesi',
    school_id: 7332,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kırıkhan Final Akademi Anadolu Fen ve Ortaokulu',
    school_id: 4239,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kırıkkale Final Okulları',
    school_id: 1834,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kırklareli Final Akademi Anadolu Lisesi',
    school_id: 4293,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kırşehir Final Okulları',
    school_id: 2625,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kırşehir Final Akademi',
    school_id: 4393,
    aim_codes: [
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kızıltepe Final Akademi',
    school_id: 8406,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'K.Adana Final Akademi Anadolu Lisesi',
    school_id: 6231,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kilis Final Okulları',
    school_id: 9008,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Malatya Final Okulları',
    school_id: 1872,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Malatya Yakınca Final Akademi Anadolu ve Fen Lisesi',
    school_id: 9010,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mardin Final Akademi Anadolu Lisesi',
    school_id: 8440,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mardin Final Okulları',
    school_id: 1904,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mersin Erdemli Final Akademi Ortaokulu - Anadolu Lisesi',
    school_id: 1956,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mersin Final Okulları',
    school_id: 1936,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mersin Mezitli Final Okulu',
    school_id: 5307,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mersin Yenişehir Final Akademi Anadolu Lisesi',
    school_id: 7943,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Muğla Final Akademi Anadolu Lisesi',
    school_id: 7537,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Milas Final Akademi Anadolu Lisesi',
    school_id: 5387,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Muğla Ortaca Final Okulları',
    school_id: 1964,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Muş Final Akademi Anadolu Lisesi',
    school_id: 5427,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Midyat Final Akademi Anadolu Lisesi',
    school_id: 5375,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Niğde Final Akademi',
    school_id: 5454,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ordu Final Akademi Anadolu ve Fen Lisesi',
    school_id: 5512,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ordu Final Okulları',
    school_id: 1991,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ünye Final Akademi Anadolu Lisesi',
    school_id: 6089,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Osmaniye Final Okulları',
    school_id: 5580,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Rize Final Akademi Anadolu Lisesi',
    school_id: 7605,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Safranbolu Final Akademi',
    school_id: 9515,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Sakarya Final Akademi ve Anadolu Lisesi',
    school_id: 5657,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'SAMSUN FİNAL AKADEMİ ANADOLU VE FEN LİSESİ',
    school_id: 5669,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Samsun Final Okulları',
    school_id: 2059,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'SİLİFKE FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 5735,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Silifke Final Okulları',
    school_id: 2113,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Silivri Final Okulları',
    school_id: 2130,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Sivas Final Akademi Anadolu Lisesi',
    school_id: 5803,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'SİİRT FİNAL AKADEMİ FEN ve ANADOLU LİSESİ',
    school_id: 5711,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Siirt Final Okulları',
    school_id: 2092,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Tarsus Final Okulları',
    school_id: 5961,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'TEKİRDAĞ FİNAL OKULU',
    school_id: 10057,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Tekirdağ Çerkezköy Final Akademi Ortaokulu - Anadolu Lisesi',
    school_id: 9899,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Trabzon Final Akademi',
    school_id: 2436,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Uşak Final Akademi',
    school_id: 6085,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Yalova Final Akademi Anadolu Lisesi',
    school_id: 6120,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ÇANAKKALE FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 4908,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Çankırı Final Akademi',
    school_id: 9975,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ÇORUM FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 7580,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Esenyurt Final Okulları',
    school_id: 1564,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'ATAŞEHİR FİNAL ANADOLU LİSESİ',
    school_id: 5081,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'BAHÇELİEVLER FİNAL AKADEMİ',
    school_id: 5073,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Bahçelievler Cumhuriyet Final Akademi Anadolu Lisesi',
    school_id: 7950,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Bahçeşehir Final Akademi Ortaokulu / Anadolu ve Fen Lisesi',
    school_id: 993,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Bahçeşehir Final Okulları',
    school_id: 1004,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Basın Express Final Okulları',
    school_id: 1034,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Beylikdüzü Final Okulları',
    school_id: 1116,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'BEŞİKTAŞ FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 7518,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Bostancı Final Okulları',
    school_id: 1157,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Büyükçekmece Final Akademi Anadolu Lisesi',
    school_id: 7578,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Fatih Final Okulları',
    school_id: 1590,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Florya Final Okulları',
    school_id: 1601,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Göktürk Final Akademi Ortaokulu - Anadolu Lisesi',
    school_id: 1663,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Güneşli Final Okulları',
    school_id: 1689,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Güngören Final Akademi Ortaokulu',
    school_id: 5509,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ispartakule',
    school_id: 1716,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Kadıköy Final Akademi Anadolu Lisesi',
    school_id: 8018,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Kavacık Final Okulları',
    school_id: 1777,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Kağıthane Final Okulları',
    school_id: 1727,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İSTANBUL KOŞUYOLU FİNAL AKADEMİ ANADOLU LİSESİ',
    school_id: 7669,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Maltepe Final Akademi Lisesi',
    school_id: 7480,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Merter Final Akademi Ortaokulu - Anadolu Lisesi',
    school_id: 626,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Mimaroba Final Okulları',
    school_id: 2452,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Pendik Final Akademi Anadolu Lisesi',
    school_id: 8039,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Pendik Final Okulları',
    school_id: 2269,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Sancaktepe Final Akademi Anadolu ve Fen Lisesi',
    school_id: 5713,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Sefaköy Final Akademi Anadolu Lisesi',
    school_id: 5694,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Tarabya Final Okulları',
    school_id: 2319,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Topkapı Final Anadolu Lisesi',
    school_id: 6000,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Yenibosna Final Okulları',
    school_id: 2176,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Çamlıca Final Okulları',
    school_id: 1263,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Çapa Final Anadolu Lisesi',
    school_id: 5296,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Çekmeköy Final Okulları',
    school_id: 1313,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Çengelköy Final Okulları',
    school_id: 1343,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İstanbul Ümraniye Şerifali Final Akademi Ortaokulu',
    school_id: 2145,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Şişli Final Akademi',
    school_id: 2602,
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İzmir Bornova Final Akademi Anadolu ve Fen Lisesi',
    school_id: 1150,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İzmir Çiğli Final Okulları',
    school_id: 5896,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BH8Z4S',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKYJYM',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BRS7S9',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Kocaeli İzmit Final Akademi Ortaokulu - Anadolu Lisesi',
    school_id: 8421,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Şanlıurfa Final Akademi Anadolu Lisesi',
    school_id: 5857,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Siverek Final Akademi Anadolu Lisesi',
    school_id: 5821,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Viranşehir Akademi Okulları',
    school_id: 6105,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Şırnak Final Akademi Anadolu Lisesi',
    school_id: 5927,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Şırnak Final Okulları',
    school_id: 2157,
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-B78PY3',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BKMK44',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-B3L4CT',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Silopi Final Akademi ve Anadolu Lisesi',
    school_id: 7647,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'İdil Final Okulları',
    school_id: 9826,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    school_name: 'Ankara Cebeci Final Akademi Anadolu Lisesi',
    school_id: 1223,
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
];

export default schoolsArr;
