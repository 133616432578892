<template>
  <div>
    <div :class="$style.divLogin">
      <!-- <img src="static/img/resim.png" style="width: 100%; height: 100%; position: absolute; bottom: 0;" alt=""> -->
      <div :class="$style.divHelpDesk">
        <HelpDesk bgcolor='white' :txtcolor='primaryColor'></HelpDesk>
      </div>
      <div :class="$style.loginform">

        <div style="color:white; text-align:center;margin-bottom:20px">
          <!-- <a :href="`https://www.myeduclass.com`"> -->
            <div :class="$style.logo"> </div>
          <!-- </a> -->
          <!-- <h1>Login</h1> -->
        </div>
        <div class="alert alert-info">
            Öğrenci Girişleri <a href="http://obs.finalokullari.com.tr/" target="_blank">Akbim OBS</a> <br>(Entegre Yazılımlar / FnlEnglish) üzerinden yapılmalıdır.
        </div>
        <div v-if="!ready">
          <InnerLoading></InnerLoading>
        </div>
        <div v-else class="row" :class="$style.inputs">
          <div class="col-md-12">
            <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-user"></i>
            <input v-model="username" style="padding-left:30px;border:none;background-color: rgba(233, 236, 239, 0.32941176470588235) !important;" type="text" class="form-control mb-3" placeholder="User Name" :class="$style.inputText">
            <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-unlock-alt"></i>
            <input v-model="password" style="padding-left:30px;border:none;background-color: rgba(233, 236, 239, 0.32941176470588235) !important;" type="password" class="form-control mb-3" placeholder="Password" :class="$style.inputText" @keyup.enter="login">
            <button class="btn btn-block" :class="$style.loginButton" @click="login">Login</button>
            <!-- <label class="mt-2 text-center" style="color:#f8f9fac9;user-select: none;">Türkiye içerisindeki öğrencilerimiz sistemi 21 Eylül'den itibaren kullanacaklardır.<br>
              Sağlıklı Günler Dileriz.</label> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import jwt from 'jsonwebtoken';
  import _ from 'lodash';
  import swal from 'sweetalert';
  // import CryptoJS from 'crypto-js';
  import config from '@/config';
  import InnerLoading from '@/components/InnerLoading';
  import HelpDesk from '@/components/HelpDesk';
  import authService from '@/services/auth';
  import axios from 'axios';



  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        isChecked: false,
        primaryColor: config.primaryColor,
        ready: true,
        ipAdress: '',
      };
    },
    beforeMount() {
      localStorage.clear();
      this.autoLogin();
    },
    components: {
      InnerLoading,HelpDesk
    },
    methods: {
      ...mapMutations(['setFirst']),
      async login() {
        this.ready = false;
        const res = await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });

        if (res.success) {
          this.setFirst(true);
          const ipData = await axios.get("https://api.ipify.org?format=json")
          .catch(err => {
              console.log('err', err);
          });

          this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

          const userAgent = navigator.userAgent;
          await authService.loginLog(userAgent, this.ipAdress).then(() => {
            // console.log('home1')
            this.$router.push('/home');
          }).catch(err => console.log('err', err));
          // console.log('home2')
          this.$router.push('/home');
        } else {
          swal('Error!', 'Username or Password is invalid.', 'error');
        }
        this.ready = true;
      },
      async autoLogin() {
        try {
          this.ready = false;
          const arr = this.$route.query;
          let decode = null;
          if (_.isEmpty(arr.sso) === false) {
            const token = arr.sso;
            decode = jwt.verify(token, '1A8379D5415BDABC927ADD7FC7E73040AD4B9AC1');
          }
          if (_.isEmpty(decode) === false) {
            const obj = {
              username: decode.TCNo,
              password: '123456',
              school_id: 63,
              campus_name: decode.branchName,
              first_name: decode.name,
              last_name: decode.surname,
              class: decode.classLevel,
              branch: decode.substation,
              branch_id: decode.branchId ? decode.branchId : 0,
            };
            /*
            // 04.01.2023
            // Her şekilde öğrenci bilgi güncelleme servisine göndermek için autoLogin içindeki login'i pasife aldım.
            const res = await this.$store.dispatch('login', {
              username: obj.username,
              password: obj.password,
            });
            if (res.success) {
              const ipData = await axios.get("https://api.ipify.org?format=json")
              .catch(err => {
                  console.log('err', err);
              });
              this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';
              const userAgent = navigator.userAgent;
              await authService.loginLog(userAgent, this.ipAdress).then(() => {
                this.$router.push('/home');
              }).catch(err => console.log('err', err));
              this.$router.push('/home');
            } else {
            */
              const res2 = await this.$store.dispatch('loginSso', obj);
              if (res2.success) {
                const ipData = await axios.get("https://api.ipify.org?format=json")
                .catch(err => {
                    console.log('err', err);
                });
                this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';
                const userAgent = navigator.userAgent;
                await authService.loginLog(userAgent, this.ipAdress).then(() => {
                  this.$router.push('/home');
                }).catch(err => console.log('err', err));
                // this.$router.push('/home');
              } else {
                console.log('Akbim Err', res2);
                swal('Giriş Yapamadınız.', 'Bilgileriniz doğrulanamadı okulunuz ile iletişime geçiniz.', 'error');
              }
            //}
          }
          this.ready = true;
        } catch (error) {
          swal('Error!', "Couldn't login, Try Again", 'error');
          this.ready = true;
        }
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style>
  .abcRioButton {
    width: 300px !important;
  }

</style>
