<template>
  <div>
    <!-- <div class="col-md-6 mt-1" v-if="userDetails.username !== 'finalteacher2'">
        <div :class="$style.divPlatform" data-toggle="modal" data-target=".bd-example-modal-lg-report">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReport]"></div>
        </div>
    </div>
    <div class="col-md-6 mt-1" v-if="userDetails.username !== 'finalteacher2'">
        <div :class="$style.divPlatform" data-toggle="modal" data-target=".bd-example-modal-lg-content" @click="monitoringSheetsClicked = false; kwButtonClicked= false;">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnContent]"></div>
        </div>
    </div> -->
    <div class="col-md-12 mt-1" style="padding:0px;">
      <div :class="$style.divPlatform" @click="$router.push('/teacherResources')">
        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeachersArea]"></div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-lg-report" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" :class="$style.modalDialog">
        <div class="modal-content" :class="$style.modalContent">
          <div class="modal-header">
            <h5 class="modal-title w-100" :class="$style.modalTitle" id="exampleModalLongTitle">FNL Report</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100" v-if="lang === 'en'">
                <!-- Normalde ortaokul öğretmenleri KW Report sayfasını görmemeli ama YLP'de ilkokul öğretmenleri de Ortaokullar da açılmış -->
                <div class="col-md-4 align-self-center">
                  <a :href="`http://report.kidzwonder.com/Account/Login${teacherKwReportCredentials}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKw]" title="KIDZWONDER"></div>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 align-self-center">
                  <a :href="`https://www.uesreport.com${teacherUesReportCredentials}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnUesReport]" title="YLP REPORT"></div>
                    </div>
                  </a>
                </div>
                <div v-if="true" class="col-md-4 align-self-center">
                  <a :href="`https://report.fnlassessment.com/#/login${querystringtext}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment]" title="ASSESSMENT EXAM REPORT"></div>
                    </div>
                  </a>
                </div>
                <div v-else class="col-md-4 align-self-center" :class="$style.disableCol">
                  <a :href="`https://report.fnlassessment.com/#/login${querystringtext}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment , $style.disableBtn]" title="ASSESSMENT EXAM REPORT"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="row h-100" v-else-if=" lang === 'de'">
                <div class="col-md-12 align-self-center">
                  <a :href="`https://admin.hexagonia.de/login${querystringtext}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]" title="HEXAGONIA"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-lg-content" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" :class="$style.modalDialog">
        <div class="modal-content" :class="$style.modalContent">
          <div class="modal-header">
            <h5 class="modal-title w-100" :class="$style.modalTitle" id="exampleModalLongTitle">Contents</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="monitoringSheetsModalClosed">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="!monitoringSheetsClicked && !kwButtonClicked && !subAimClicked && !placementButtonClicked" class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100" v-if="lang === 'en'">
                <div class="col-md-3 align-self-center">
                  <div :class="$style.divPlatform" @click="kwButtonClicked = true">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKw]" title="KIDZWONDER"></div>
                  </div>
                </div>
                <div class="col-md-3 align-self-center">
                  <a href="/mycontents" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformYlp]" title="YOUR LEARNING PLACE"></div>
                    </div>
                  </a>
                </div>
                <div class="col-md-3 align-self-center">
                  <div v-if="schoolData.is_digital" :class="$style.divPlatform" @click="monitoringSheetsClicked = true">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim]" title="MONITORING SHEETS"></div>
                  </div>
                  <div v-else :class="[$style.divPlatform, $style.disableCol]">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim, $style.disableBtn]" title="MONITORING SHEETS"></div>
                  </div>
                </div>
                <div class="col-md-3 align-self-center">
                  <div :class="$style.divPlatform" @click="placementButtonClicked = true">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnlPlacement]" title="FNL Placement Exam"></div>
                  </div>
                </div>
              </div>
              <div class="row h-100" v-else-if="lang === 'de'">
                <div v-if="lang === 'de'" class="col-md-12 align-self-center">
                  <a :href="`https://hexagonia.de${hexagonia5Credentials}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]" title="HEXAGONIA"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="monitoringSheetsClicked" class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100">
                <div class="col-md-3 align-self-center mb-1" v-for="(item, i) in aimData" :key="i">
                  <div v-if="item.query === 'grade'" :class="$style.divPlatform" @click="goToAim(item.codes.find(x => x.title === String(item.grade)).code)">
                    <div :class="$style.txtPlatform">{{item.grade}}</div>
                  </div>
                  <div v-if="item.query === 'className'" :class="$style.divPlatform">
                    <div :class="$style.txtPlatform" @click="getAimClasses(item.grade)">{{item.grade}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="subAimClicked" class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100">
                <div class="col-md-3 align-self-center mb-1" v-for="(item, i) in subAimData.codes" :key="i">
                  <div :class="$style.divPlatform">
                    <div :class="$style.txtPlatform" @click="goToAim(item.code)">{{item.title}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="kwButtonClicked" class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100">
                <div class="col-md-3 align-self-center mb-1">
                  <a :href="`http://kidzwonder.com${teacherKwCredentials1}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">1</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-3 align-self-center mb-1">
                  <a :href="`http://kidzwonder.com${teacherKwCredentials2}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">2</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-3 align-self-center mb-1">
                  <a :href="`http://kidzwonder.com${teacherKwCredentials3}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">3</div>
                    </div>
                  </a>
                </div>
                <div class="col-md-3 align-self-center mb-1">
                  <a :href="`http://kidzwonder.com${teacherKwCredentials4}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">4</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="placementButtonClicked" class="modal-body">
            <div class="container-fluid h-100">
              <div class="row h-100">
                <div class="col-md-6 align-self-center mb-1">
                  <a :href="`http://fnlplacement.com${placementSecondaryCode}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">Secondary <span><i class="fas fa-pencil-alt"></i></span></div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 align-self-center mb-1">
                  <a :href="`http://fnlplacement.com${placementHighCode}`" target="_blank">
                    <div :class="$style.divPlatform">
                      <div :class="$style.txtPlatform">High <span><i class="fas fa-pencil-alt"></i></span></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tippy from 'tippy.js';
import schools from '../../../static/js/schools';

export default {
  name: 'platforms',
  data() {
    return {
      monitoringSheetsClicked: false,
      kwButtonClicked: false,
      placementButtonClicked: false,
      placementSecondaryCode: '',
      placementHighCode: '',
      querystringtext: '',
      teacherKwCredentials1: '',
      teacherKwCredentials2: '',
      teacherKwCredentials3: '',
      teacherKwCredentials4: '',
      hexagonia5Credentials: '',
      teacherKwReportCredentials: '',
      teacherUesReportCredentials: '',
      aimData: [],
      subAimData: [],
      subAimClicked: false,
      schoolData: {},
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password', 'grade', 'lang']),

  },
  beforeMount() {
    this.setData();
  },
  mounted() {
    tippy('[title]', {
      placement: 'top',
    });
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    setData() {
      if (this.userDetails) {
        this.querystringtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        this.teacherKwCredentials1 = `?username=${btoa('teacherkw00188')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials2 = `?username=${btoa('teacherkw02715')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials3 = `?username=${btoa('teacherkw05188')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials4 = `?username=${btoa('teacherkw07677')}&password=${btoa('abijrs')}`;
        this.hexagonia5Credentials = `?username=${btoa('hexagonia01')}&password=${btoa('hexagonia01')}`;
        this.placementSecondaryCode = `?code=${btoa('secondary')}`;
        this.placementHighCode = `?code=${btoa('high')}`;
        const schoolData = schools.find(x => x.ylp_id === 6340595595083776);
        if (schoolData) {
          this.schoolData = schoolData;
          if (schoolData.is_digital && schoolData.aim_codes.length > 0) {
            this.aimData = schoolData.aim_codes;
          }
        }
        this.teacherKwReportCredentials = `?username=${btoa(schoolData.kidzwonder_report_username)}&password=${btoa('123456')}`;
        if (this.username === 'tahsinsancili18' || this.username === 'rifatozturk18') {
          this.teacherUesReportCredentials = `?username=${btoa('ues_final')}&password=${btoa('Ues1234!')}`;
        } else {
          this.teacherUesReportCredentials = this.querystringtext;
        }
      }
    },
    monitoringSheetsModalClosed() {
      this.monitoringSheetsClicked = false;
      this.kwButtonClicked = false;
      this.subAimClicked = false;
      this.placementButtonClicked = false;
    },
    goToAim(code) {
      console.log(code)
      window.open(`http://wths.uespublishing.com/login.html?code=${btoa(code)}`, '_blank');
    },
    getAimClasses(grade) {
      const schoolData = schools.find(x => x.ylp_id === this.$store.state.userDetails.school_id);
      this.subAimData = schoolData.aim_codes.find(x => x.grade === grade);
      this.monitoringSheetsClicked = false;
      this.subAimClicked = true;
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
