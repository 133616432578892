const schoolsArr = [
  {
    ylp_username: 'fnltrabzonakademiylp_admin',
    ylp_id: 6223706132840448,
    ylp_name: 'TRABZON FİNAL OKULLARI AKADEMİ YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkirsehirkw_admin',
    ylp_id: 5630960405839872,
    ylp_name: 'KIRŞEHİR FİNAL OKULLARI KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kirsehirfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkirsehirylp_admin',
    ylp_id: 6048207989374976,
    ylp_name: 'KIRŞEHİR FİNAL OKULLARI YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kirsehirfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5SXKLT',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnladanaylp_admin',
    ylp_id: 4832640303366144,
    ylp_name: 'ADANA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'adanafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-BBKWWQ',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBRDTJ',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBWYMZ',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB5K2B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCGYZF',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCV8FJ',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC7SFM',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnladanakw_admin',
    ylp_id: 6582567886913536,
    ylp_name: 'ADANA FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'adanafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-BBKWWQ',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBRDTJ',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBWYMZ',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB5K2B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCGYZF',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCV8FJ',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC7SFM',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbaglicaakademiylp_admin',
    ylp_id: 6032544968212480,
    ylp_name: 'BAĞLICA FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcayyoluylp_admin',
    ylp_id: 6343053994098688,
    ylp_name: 'ÇAYYOLU FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'cayyolufinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcebeciakademiylp_admin',
    ylp_id: 6605853580591104,
    ylp_name: 'Ankara Cebeci Final Akademi Anadolu Lisesi YLP 2018-2019',
    is_digital: true,
    level: 'Lise',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlumitkoyakademiylp_admin',
    ylp_id: 5037770181181440,
    ylp_name: 'ÜMİTKÖY FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlavcilarylp_admin',
    ylp_id: 6490172428124160,
    ylp_name: 'AVCILAR FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'avcilarfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlakademibahcesehir_admin',
    ylp_id: 5037997576421376,
    ylp_name: 'FİNAL AKADEMİ BAHÇEŞEHİR KOLEJİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbeylikduzuylp_admin',
    ylp_id: 5513031918026752,
    ylp_name: 'BEYLİKDÜZÜ FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'beylikduzufinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbursakmlpsaakdemi_admin',
    ylp_id: 5171292523986944,
    ylp_name: 'ANKARA MUSTAFA KEMAL FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcengelkoyylp_admin',
    ylp_id: 6440782569144320,
    ylp_name: 'ÇENGELKÖY FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalcengelkoykw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldenizliakademi_admin',
    ylp_id: 5130737600430080,
    ylp_name: 'DENİZLİ FİNAL OKULLARI AKADEMİ YLP 2018-',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnleryamanylp_admin',
    ylp_id: 6659757765820416,
    ylp_name: 'FİNAL OKULLARI ERYAMAN YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'eryamanfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCL3BY',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'className',
        codes: [
          {
            title: '7-A',
            code: 'AIM-BCYMZP',
          },
          {
            title: '7-B',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlesenyurtylp_admin',
    ylp_id: 4999978948493312,
    ylp_name: 'ESENYURT FİNAL OKULLARI YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'esenyurtfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnletimesgutakademiylp_admin',
    ylp_id: 6602583161176064,
    ylp_name: 'ETİMESGUT FİNAL AKADEMİ KOLEJİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlaksehirylp_admin',
    ylp_id: 5259324122988544,
    ylp_name: 'FİNAL OKULLARI AKŞEHİR YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'aksehirfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlelvankentylp_admin',
    ylp_id: 6267400502378496,
    ylp_name: 'ELVANKENT FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbasinyoluylp_admin',
    ylp_id: 5925284270047232,
    ylp_name: 'BASINYOLU FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'basinyolu_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbsehiresenkent_admin',
    ylp_id: 5378317131448320,
    ylp_name: 'BAHÇEŞEHİR ESENKENT FİNAL YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bsehiresenkentfnl_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbasaksehirylp_admin',
    ylp_id: 5961510054264832,
    ylp_name: 'BAŞAKŞEHİR FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'basaksehirfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbostanciylp_admin',
    ylp_id: 5781648484073472,
    ylp_name: 'BOSTANCI FİNAL OKULLARI 2018-2019 YLP',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bostancifinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbursagorukleylp_admin',
    ylp_id: 5944407293165568,
    ylp_name: 'BURSA GÖRÜKLE FİNAL OKULLARI YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bursagoruklefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcamlicaylp_admin',
    ylp_id: 6666450734153728,
    ylp_name: 'FİNAL OKULLARI ÇAMLİCA YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcizreylp_admin',
    ylp_id: 5337794903539712,
    ylp_name: 'CİZRE FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: false,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldiyarbakirkylp_admin',
    ylp_id: 5490579255066624,
    ylp_name: 'DİYARBAKIR KAYAPINAR FİNAL OKULLARI YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: false,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlfatihylp_admin',
    ylp_id: 6156061201137664,
    ylp_name: 'FATİH FİNAL OKULLARI YLP2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgebzemutlukentylp_admin',
    ylp_id: 5840415176261632,
    ylp_name: 'GEBZE MUTLUKENT FİNAL YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalgebzemutlukentkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgolcukylp_admin',
    ylp_id: 6558200901664768,
    ylp_name: 'GÖLCÜK FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'golcukfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankrakeciorenylp_admin',
    ylp_id: 5049448734130176,
    ylp_name: 'ANKARA KEÇİÖREN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalankrakeciorenkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlantalyakemerylp_admin',
    ylp_id: 4745836184207360,
    ylp_name: 'ANTALYA KEMER FİNAL OKULLARI 2018-2019 YLP',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kemerfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmuglaortacaylp_admin',
    ylp_id: 5213429139505152,
    ylp_name: 'MUGLA ORTACA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalmuglaortacakw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkavacikylp_admin',
    ylp_id: 4634247870283776,
    ylp_name: 'KAVACIK FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kavacikfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'daricafinalylp_admin',
    ylp_id: 4685573229641728,
    ylp_name: 'DARICA FİNAL LİSELERİ YLP 2018-2019',
    level: 'Lise',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'daricafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlerzurumylp_admin',
    ylp_id: 4824798523555840,
    ylp_name: 'ERZURUM FİNAL OKULLARI YLP 2018 - 2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankaraparktwinylp_admin',
    ylp_id: 4866591706316800,
    ylp_name: 'ANKARA PARK TWİN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'twinfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BCC6M2',
          },
          {
            title: '9-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldogubeyazitylp_admin',
    ylp_id: 4902131943079936,
    ylp_name: 'DOĞU BEYAZIT FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankaraoranylp_admin',
    ylp_id: 4932558917468160,
    ylp_name: 'ANKARA ORAN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ankaraoranfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsiirtylp_admin',
    ylp_id: 4991617301479424,
    ylp_name: 'SİİRT FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkayseriylp_admin',
    ylp_id: 5049361777819648,
    ylp_name: 'KAYSERİ FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kayserifnlkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlfloryaylp_admin',
    ylp_id: 5059817587343360,
    ylp_name: 'FLORYA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'floryafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDLYMR',
          },
          {
            title: '9-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'className',
        codes: [
          {
            title: '11-A',
            code: 'AIM-AEF41',
          },
          {
            title: '11-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbatikentylp_admin',
    ylp_id: 5105290472849408,
    ylp_name: 'BATIKENT FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'batikentfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCL3BY',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'className',
        codes: [
          {
            title: '7-A',
            code: 'AIM-BCYMZP',
          },
          {
            title: '7-B',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkartalakademi_admin',
    ylp_id: 5128890425802752,
    ylp_name: 'KARTAL FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbursaylp_admin',
    ylp_id: 5148531918372864,
    ylp_name: 'BURSA FİNAL OKULLARI YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bursafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'className',
        codes: [
          {
            title: '1-A',
            code: 'AIM-4Z6483',
          },
          {
            title: '1-B',
            code: 'AIM-BBKWWQ',
          },
        ],
      },
      {
        grade: 2,
        query: 'className',
        codes: [
          {
            title: '2-A',
            code: 'AIM-BBRDTJ',
          },
          {
            title: '2-B',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBWYMZ',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB5K2B',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BCC6M2',
          },
          {
            title: '5-B',
            code: 'AIM-BCGYZF',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCV8FJ',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BDCF3G',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlceyhanylp_admin',
    ylp_id: 5152099912122368,
    ylp_name: 'CEYHAN FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldenizliylp_admin',
    ylp_id: 5192057158893568,
    ylp_name: 'DENİZLİ FİNAL OKUILLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'denizlifinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgokturkylp_admin',
    ylp_id: 5223311023538176,
    ylp_name: 'GÖKTÜRK FİNAL KOLEJİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlispartakuleylp_admin',
    ylp_id: 5266791234011136,
    ylp_name: 'ISPARTAKULE FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ispartakulefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlakademimecidiyekoy_admin',
    ylp_id: 5318494978310144,
    ylp_name: 'MECİDİYEKÖY FİNAL AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmerterakademiylp_admin',
    ylp_id: 5339998473420800,
    ylp_name: 'MERTER FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmardinylp_admin',
    ylp_id: 5491687051493376,
    ylp_name: 'MARDİN FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlakademimersinerdemli_admin',
    ylp_id: 5508267302977536,
    ylp_name: 'FİNAL AKADEMİ MERSİN ERDEMLİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsilivriokullariylp_admin',
    ylp_id: 5544505821102080,
    ylp_name: 'SİLİVRİ FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkirikkaleylp_admin',
    ylp_id: 5614584456806400,
    ylp_name: 'KIRIKKALE FİNAL OKULLARI 2018-2019 YLP',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kirikkalefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlorduylp_admin',
    ylp_id: 5648417138933760,
    ylp_name: 'ORDU FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsilifkeylp_admin',
    ylp_id: 5649591762157568,
    ylp_name: 'SİLİFKE FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgaziantepnizipylp_admin',
    ylp_id: 5709538684305408,
    ylp_name: 'GAZİANTEP NİZİP FİNAL YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalgaziantepnizipkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcapaylp_admin',
    ylp_id: 5815237032542208,
    ylp_name: 'ÇAPA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlorduakademiylp_admin',
    ylp_id: 5842843178106880,
    ylp_name: 'ORDU FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlantalyakoleji_admin',
    ylp_id: 5980098689499136,
    ylp_name: 'ANTALYA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'antalyafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-AEF41',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbduzukavakliylp_admin',
    ylp_id: 6045490950701056,
    ylp_name: 'BEYLİKDÜZÜ KAVAKLI FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlpendikylp_admin',
    ylp_id: 6179847459831808,
    ylp_name: 'PENDİK FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'pendikfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlelbistanylp_admin',
    ylp_id: 6230319726067712,
    ylp_name: 'ELBİSTAN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: false,
    is_assessment: true,
    kidzwonder_report_username: 'elbistanfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlozelelazigylp_admin',
    ylp_id: 6234622273519616,
    ylp_name: 'ÖZEL ELAZIĞ FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ozelelazigfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlserifaliakademiylp_admin',
    ylp_id: 6246943628722176,
    ylp_name: 'ÜMRANİYE ŞERİFALİ FİNAL AKADEMİ ANADOLU LİSESİ 2018-2019',
    level: 'Lise',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5NTRPW',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsamsunylp_admin',
    ylp_id: 6340595595083776,
    ylp_name: 'SAMSUN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'samsunfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkutahyaakademi_admin',
    ylp_id: 6375779967172608,
    ylp_name: 'KÜTAHYA FİNAL AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-6WT49Q',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbatmanylp_admin',
    ylp_id: 6425653882388480,
    ylp_name: 'BATMAN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalbatmankw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbornovaakademiylp_admin',
    ylp_id: 6447487466078208,
    ylp_name: 'Bornova Final Akademi Anadolu ve Fen Lisesi YLP 2018-2019',
    is_digital: true,
    level: 'Lise',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmalatyaylp_admin',
    ylp_id: 6490889964486656,
    ylp_name: 'MALATYA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'malatyafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgaziantepakademi_admin',
    ylp_id: 6491065915539456,
    ylp_name: 'GAZİANTEP FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnleregliakademiylp_admin',
    ylp_id: 6503512291409920,
    ylp_name: 'EREĞLİ FİNAL OKULLARI AKADEMİ YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-6X3P7G',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-64FR9F',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcekmekoyylp_admin',
    ylp_id: 6622786418900992,
    ylp_name: 'FİNAL OKULLARI ÇEKMEKÖY YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'cekmekoyfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'className',
        codes: [
          {
            title: '3-A',
            code: 'AIM-BBWYMZ',
          },
          {
            title: '3-B',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgunesliylp_admin',
    ylp_id: 6705822430920704,
    ylp_name: 'GÜNEŞLİ FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'guneslifinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'className',
        codes: [
          {
            title: '11-A',
            code: 'AIM-AEF41',
          },
          {
            title: '11-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlyenibosnaylp_admin',
    ylp_id: 6707869824843776,
    ylp_name: 'YENİBOSNA FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'yenibosnafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsirnakylp_admin',
    ylp_id: 6716354549514240,
    ylp_name: 'ŞIRNAK FİNAL OKULLARI YLP 2018 - 2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: '',
    ylp_id: 5823807623004160,
    ylp_name: 'KAĞITHANE FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kagithanefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsusurlukylp_admin',
    ylp_id: 5663525894356992,
    ylp_name: 'BALIKESİR SUSRLUK FİNAL OKULLARI AKADEMİ 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlmimarobaylp_admin',
    ylp_id: 5462117924470784,
    ylp_name: 'FİNAL OKULLARI MİMAROBA YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'mimarobafnlkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnltarabyaylp_admin',
    ylp_id: 6166722241101824,
    ylp_name: 'FİNAL OKULLARI TARABYA YLP 2018 - 2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'tarabyafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkonyaakademi_admin',
    ylp_id: 5506943231459328,
    ylp_name: 'KONYA FİNAL OKULLARI AKADEMİ 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-5NTRPW',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-5SXKLT',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-5WHFJ9',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmersinylp_admin',
    ylp_id: 6165598830329856,
    ylp_name: 'MERSİN FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'mersinfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlerzurumhedefylp_admin',
    ylp_id: 4720308394917888,
    ylp_name: 'ERZURUM HEDEF FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalerzurumhedefkw_teacher',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlkarabukylp_admin',
    ylp_id: 4584297769730048,
    ylp_name: 'KARABÜK FİNAL OKULLARI YLP 2018-2019',
    level: 'Ortaokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalkarabukkw_teacher',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlordutemelliseylp_admin',
    ylp_id: 5830376112783360,
    ylp_name: 'ORDU FİNAL TEMEL LİSE 2018 - 2019',
    is_digital: true,
    level: 'Lise',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlbozuyuktemellise_admin',
    ylp_id: 5829405013966848,
    ylp_name: 'BOZÜYÜK ÖZEL FİNAL TEMEL LİSESİ 2018 - 2019',
    is_digital: true,
    level: 'Lise',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlsanliurfaylp_admin',
    ylp_id: 4812663353507840,
    ylp_name: 'ŞANLIURFA FİNAL OKULLARI YLP 2018-2019',
    is_digital: false,
    level: 'Ortaokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsamsunkw_admin',
    ylp_id: 4652524835176448,
    ylp_name: 'SAMSUN FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'samsunfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'kavacikfinalkw_admin',
    ylp_id: 5853641061433344,
    ylp_name: 'ÖZEL KAVACIK FİNAL OKULU KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kavacikfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmuglaortacakw_admin',
    ylp_id: 6321621835710464,
    ylp_name: 'MUĞLA ORTACA FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalmuglaortacakw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmersinfinalkw_admin',
    ylp_id: 5318982255771648,
    ylp_name: 'MERSİN FİNAL ORTAOKULU KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'mersinfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmardinkw_admin',
    ylp_id: 5334869205581824,
    ylp_name: 'MARDİN FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgebzemutlukentkw_admin',
    ylp_id: 5942929401839616,
    ylp_name: 'GEBZE MUTLUKENT FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalgebzemutlukentkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkarabukkw_admin',
    ylp_id: 5305186250391552,
    ylp_name: 'KARABÜK FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalkarabukkw_teacher',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlsiirtkw_admin',
    ylp_id: 4848044594692096,
    ylp_name: 'SİİRT FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankrakeciorenkw_admin',
    ylp_id: 6006748894461952,
    ylp_name: 'ANKARA KEÇİÖREN FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalankrakeciorenkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbatmankw_admin',
    ylp_id: 5656450875260928,
    ylp_name: 'BATMAN FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalbatmankw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsilifkekw_admin',
    ylp_id: 5108388704550912,
    ylp_name: 'SİLİFKE FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcengelkoykw_admin',
    ylp_id: 5457655898046464,
    ylp_name: 'ÇENGELKÖY FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalcengelkoykw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-AEF41',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcizrekw_admin',
    ylp_id: 6476996995186688,
    ylp_name: 'CİZRE FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgaziantepnizipkw_admin',
    ylp_id: 5130771641401344,
    ylp_name: 'GAZİANTEP NİZİP FİNAL KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalgaziantepnizipkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlerzurumhedefkw_admin',
    ylp_id: 5007318477176832,
    ylp_name: 'ERZURUM HEDEF FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'finalerzurumhedefkw_teacher',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlelbistankw_admin',
    ylp_id: 5074420345077760,
    ylp_name: 'ELBİSTAN FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: 'elbistanfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlgolcukkw_admin',
    ylp_id: 4515984385507328,
    ylp_name: 'GÖLCÜK FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'golcukfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankaraparktwinkw_admin',
    ylp_id: 4953368936841216,
    ylp_name: 'ANKARA PARK TWİN FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'twinfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BCC6M2',
          },
          {
            title: '9-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbeylikduzukw_admin',
    ylp_id: 5679583200018432,
    ylp_name: 'BEYLİKDÜZÜ FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'beylikduzufinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbasinyolukw_admin',
    ylp_id: 4550353837883392,
    ylp_name: 'BASINYOLU FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'basinyolu_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkagithanekw_admin',
    ylp_id: 5294295105667072,
    ylp_name: 'KAĞITHANE FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kagithanefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkayserikw_admin',
    ylp_id: 5418154807001088,
    ylp_name: 'KAYSERİ FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kayserifnlkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmalatyakw_admin',
    ylp_id: 5927739254898688,
    ylp_name: 'MALATYA FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'malatyafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldenizlikw_admin',
    ylp_id: 6602530894905344,
    ylp_name: 'DENİZLİ FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'denizlifinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbduzukavaklikw_admin',
    ylp_id: 6142334879924224,
    ylp_name: 'BEYLİKDÜZÜ KAVAKLI FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlmimarobakw_admin',
    ylp_id: 5966431944179712,
    ylp_name: 'MİMAROBA FİNAL KOLEJİ KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'mimarobafnlkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlpendikkw_admin',
    ylp_id: 6580978547687424,
    ylp_name: 'PENDİK FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'pendikfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldyrbkryenisehirkw_admin',
    ylp_id: 5877980683042816,
    ylp_name: 'DİYARBAKIR YENİŞEHİR FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [],
  },
  {
    ylp_username: 'fnlbasaksehirkw_admin',
    ylp_id: 4621458783076352,
    ylp_name: 'BAŞAKŞEHİR FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'basaksehirfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlurfakw_admin',
    ylp_id: 6753426115133440,
    ylp_name: 'URFA FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbursagoruklekw_admin',
    ylp_id: 5151741383016448,
    ylp_name: 'BURSA GÖRÜKLE FİNAL OKULLARI KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bursagoruklefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbursakw_admin',
    ylp_id: 4827315035701248,
    ylp_name: 'BURSA FİNAL OKULLARI KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bursafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'className',
        codes: [
          {
            title: '1-A',
            code: 'AIM-4Z6483',
          },
          {
            title: '1-B',
            code: 'AIM-BBKWWQ',
          },
        ],
      },
      {
        grade: 2,
        query: 'className',
        codes: [
          {
            title: '2-A',
            code: 'AIM-BBRDTJ',
          },
          {
            title: '2-B',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBWYMZ',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB5K2B',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BCC6M2',
          },
          {
            title: '5-B',
            code: 'AIM-BCGYZF',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCV8FJ',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BDCF3G',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlelvankentkw_admin',
    ylp_id: 5187855246426112,
    ylp_name: 'ELVANKENT FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlfloryakw_admin',
    ylp_id: 5315032899125248,
    ylp_name: 'FLORYA FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'floryafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDLYMR',
          },
          {
            title: '9-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'className',
        codes: [
          {
            title: '11-A',
            code: 'AIM-AEF41',
          },
          {
            title: '11-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlyenibosnakw_admin',
    ylp_id: 6667273022210048,
    ylp_name: 'YENİBOSNA FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'yenibosnafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlesenyurtkw_admin',
    ylp_id: 6520962830565376,
    ylp_name: 'ESENYURT FİNAL OKULLARI KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'esenyurtfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlantalyakw_admin',
    ylp_id: 6476274652151808,
    ylp_name: 'ANTALYA FİNAL OKULLARI KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'antalyafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-AEF41',
          },
          {
            title: '10-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlceyhankw_admin',
    ylp_id: 6469812185989120,
    ylp_name: 'CEYHAN FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlankaraorankw_admin',
    ylp_id: 6447508722810880,
    ylp_name: 'ANKARA ORAN FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ankaraoranfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlsirnakkw_admin',
    ylp_id: 6352322080538624,
    ylp_name: 'ŞIRNAK FİNAL OKULLARI KW 2018 - 2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlkirikkalekw_admin',
    ylp_id: 6208307179814912,
    ylp_name: 'KIRIKKALE FİNAL OKULLARI 2018-2019 KW',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kirikkalefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnltarabyakw_admin',
    ylp_id: 6149552186589184,
    ylp_name: 'FİNAL OKULLARI TARABYA KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'tarabyafinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnleryamankw_admin',
    ylp_id: 6148266087153664,
    ylp_name: 'FİNAL OKULLARI ERYAMAN KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'eryamanfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCL3BY',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'className',
        codes: [
          {
            title: '7-A',
            code: 'AIM-BCYMZP',
          },
          {
            title: '7-B',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbatikentkw_admin',
    ylp_id: 6036003087974400,
    ylp_name: 'BATIKENT FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'batikentfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'className',
        codes: [
          {
            title: '5-A',
            code: 'AIM-BB82ZT',
          },
          {
            title: '5-B',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'className',
        codes: [
          {
            title: '6-A',
            code: 'AIM-BCL3BY',
          },
          {
            title: '6-B',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'className',
        codes: [
          {
            title: '7-A',
            code: 'AIM-BCYMZP',
          },
          {
            title: '7-B',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-AEF41',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlantalyakemerkw_admin',
    ylp_id: 5953908381581312,
    ylp_name: 'ANTALYA KEMER FİNAL OKULLARI 2018-2019 KW',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'kemerfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbostancikw_admin',
    ylp_id: 5823929950928896,
    ylp_name: 'BOSTANCI FİNAL OKULLARI 2018-2019 KW',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'bostancifinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlguneslikw_admin',
    ylp_id: 5757754859585536,
    ylp_name: 'GÜNEŞLİ FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'guneslifinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'className',
        codes: [
          {
            title: '11-A',
            code: 'AIM-AEF41',
          },
          {
            title: '11-B',
            code: 'AIM-BDSD2N',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcayyolukw_admin',
    ylp_id: 5387235614261248,
    ylp_name: 'ÇAYYOLU FİNAL OKULLARI KW 2018 -2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'cayyolufinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'className',
        codes: [
          {
            title: '9-A',
            code: 'AIM-BDMJWS',
          },
          {
            title: '9-B',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'className',
        codes: [
          {
            title: '10-A',
            code: 'AIM-BDSD2N',
          },
          {
            title: '10-B',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldogubeyazitkw_admin',
    ylp_id: 5488995938861056,
    ylp_name: 'DOĞU BEYAZIT FİNAL OKULLARI KW 2018-2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlozelelazigkw_admin',
    ylp_id: 5502419976585216,
    ylp_name: 'ÖZEL ELAZIĞ FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ozelelazigfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlavcilarkw_admin',
    ylp_id: 5526295196467200,
    ylp_name: 'AVCILAR FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'avcilarfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlispartakulekw_admin',
    ylp_id: 5677329810194432,
    ylp_name: 'ISPARTAKULE FİNAL OKULLARI KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'ispartakulefinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnldiyarbakirkkw_admin',
    ylp_id: 5726339254190080,
    ylp_name: 'DİYARBAKIR KAYAPINAR FİNAL OKULLARI KW 2018 - 2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDLYMR',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-44K6CB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'daricafinalkw_admin',
    ylp_id: 6622607506669568,
    ylp_name: 'DARICA FİNAL KOLEJİ KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'daricafinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlcekmekoykw_admin',
    ylp_id: 5500051771883520,
    ylp_name: 'FİNAL OKULLARI ÇEKMEKÖY KW 2018 - 2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'cekmekoyfinal_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'className',
        codes: [
          {
            title: '3-A',
            code: 'AIM-BBWYMZ',
          },
          {
            title: '3-B',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BCC6M2',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCP8QV',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BC4THW',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlbsehiresenkent_admin',
    ylp_id: 4585340772548608,
    ylp_name: 'BAHÇEŞEHİR ESENKENT FİNAL KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'fnlbsehiresenkentkw_admin',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlaksehirkw_admin',
    ylp_id: 5452178355126272,
    ylp_name: 'FİNAL OKULLARI AKŞEHİR KW 2018-2019',
    level: 'İlkokul',
    is_digital: true,
    is_assessment: true,
    kidzwonder_report_username: 'aksehirfinalkw_teacher',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
      {
        grade: 10,
        query: 'grade',
        codes: [
          {
            title: '10',
            code: 'AIM-BDSD2N',
          },
        ],
      },
      {
        grade: 11,
        query: 'grade',
        codes: [
          {
            title: '11',
            code: 'AIM-4559FB',
          },
        ],
      },
    ],
  },
  {
    ylp_username: 'fnlerzurumkw_admin',
    ylp_id: 5571754905567232,
    ylp_name: 'ERZURUM FİNAL OKULLARI KW 2018 - 2019',
    is_digital: false,
    level: 'İlkokul',
    is_assessment: true,
    kidzwonder_report_username: '',
    assessment_report_username: '',
    aim_codes: [
      {
        grade: 1,
        query: 'grade',
        codes: [
          {
            title: '1',
            code: 'AIM-4Z6483',
          },
        ],
      },
      {
        grade: 2,
        query: 'grade',
        codes: [
          {
            title: '2',
            code: 'AIM-BBPH6B',
          },
        ],
      },
      {
        grade: 3,
        query: 'grade',
        codes: [
          {
            title: '3',
            code: 'AIM-BBSV67',
          },
        ],
      },
      {
        grade: 4,
        query: 'grade',
        codes: [
          {
            title: '4',
            code: 'AIM-BB2NPM',
          },
        ],
      },
      {
        grade: 5,
        query: 'grade',
        codes: [
          {
            title: '5',
            code: 'AIM-BB82ZT',
          },
        ],
      },
      {
        grade: 6,
        query: 'grade',
        codes: [
          {
            title: '6',
            code: 'AIM-BCL3BY',
          },
        ],
      },
      {
        grade: 7,
        query: 'grade',
        codes: [
          {
            title: '7',
            code: 'AIM-BCYMZP',
          },
        ],
      },
      {
        grade: 8,
        query: 'grade',
        codes: [
          {
            title: '8',
            code: 'AIM-BDGTX3',
          },
        ],
      },
      {
        grade: 9,
        query: 'grade',
        codes: [
          {
            title: '9',
            code: 'AIM-BDMJWS',
          },
        ],
      },
    ],
  },
];
export default schoolsArr;
