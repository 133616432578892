module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#012866',
    secondaryColor: '#ed1c25',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'Lv723ENFr7kd46QkVtG3sS0soO98GK5b3l0fy2v5KQY2RdTC',
};
