<template>
<div>
  <HeaderCustom v-if="!isHome"></HeaderCustom>
  <!-- <div class="container" style="margin-left:0px; margin-right:0px; padding:0px;"> -->
    <div class="row mt-5" v-if="!isHome">
      <div class="container">
        <div class="row">
<!-- KADRİ PLATFORMLARI DEĞİŞTİ-->
          <!-- <template v-if="!isStudent">
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 1 EN</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel1}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderPirate]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 2 EN</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel2}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderPirate]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 3 EN</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel3}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 4 EN</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel4}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
                  </div>
                </a>
            </div>
          </template> -->
            <!-- Almanca -->
            <!-- <template v-if="!isStudent"> 
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 1 DE</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel1de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderPirate]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 2 DE</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel2de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderPirate]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 3 DE</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel3de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Kidzwonder Grade 4 DE</span>
                <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel4de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
                  </div>
                </a>
            </div>
          </template>-->
<!-- KADRİ PLATFORMLARI DEĞİŞTİ-->

          <!-- <template v-if="!isStudent">
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 5</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel5de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaDe]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 6</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel6de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaDe]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 7</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel7de}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaDe]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
            </div>
          </template> -->
          <template v-if="!isStudent && this.campusType != 'campus'">
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 5</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel5}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaEn]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 6</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel6}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaEn]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
              <span>Hexagonia Grade 7</span>
                <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel7}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaEn]"></div>
                  </div>
                </a>
            </div>
            <div class="col-md-3 mt-5">
            </div>
          </template> 

          <template v-if="!isStudent">

            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 1</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel1User, hllDemoPass, hllDemoLevel1Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 2</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel2User, hllDemoPass, hllDemoLevel2Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 3</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel3User, hllDemoPass, hllDemoLevel3Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && APlusListOkullar() && APlusListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 3</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel3User, hllDemoPass, hllDemoLevel3Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>

            <div class="col-md-3 mt-5">
            </div>
            
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 4</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel4User, hllDemoPass, hllDemoLevel4Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && APlusListOkullar() && APlusListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 4</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel4User, hllDemoPass, hllDemoLevel4Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 5</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel5User, hllDemoPass, hllDemoLevel5Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 6</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel6User, hllDemoPass, hllDemoLevel6Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>
            
            <div class="col-md-3 mt-5" v-if="this.campusType === 'campus' && AListOkullar() && AListPlatformVarmi().includes('hll')">
              <span>Highlights Grade 7</span>
              <a>
                <div :class="$style.divPlatform" @click="checkHll(hllDemoLevel7User, hllDemoPass, hllDemoLevel7Class)">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                </div>
              </a>
            </div>

          </template>

          <!-- <template v-if="!isStudent">
                <div class="col-md-3 mt-5">
                  <span>Karneval Grade 1-2</span>
                    <a :href="`https://www.karnevall.com${karnevalstingtextDemoLevel2}`" target="_blank">
                      <div :class="$style.divPlatform">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKarnevall]"></div>
                      </div>
                    </a>
                </div>
                <div class="col-md-3 mt-5">
                  <span>Karneval Grade 3</span>
                    <a :href="`https://www.karnevall.com${karnevalstingtextDemoLevel3}`" target="_blank">
                      <div :class="$style.divPlatform">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKarnevall]"></div>
                      </div>
                    </a>
                </div>
                <div class="col-md-3 mt-5">
                  <span>Karneval Grade 4</span>
                    <a :href="`https://www.karnevall.com${karnevalstingtextDemoLevel4}`" target="_blank">
                      <div :class="$style.divPlatform">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKarnevall]"></div>
                      </div>
                    </a>
                </div>
                <div class="col-md-3 mt-5">
                </div>
              </template> -->
      </div>
    </div>
    
    </div>
    <div v-else class="row mt-1" :class="$style.divPlatformWidth">
      <template v-if="isStudent">

        <template v-if="this.rlpCode != null && this.rlpCode != '###'">
          <div class="col-md-6 mt-1">
            <a data-toggle="modal" data-target="#rlpDescription">             
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
              </div>
            </a>
          </div>
        </template>
        <template v-else-if="this.rlpCode == '###'">
          <div class="col-md-6 mt-1">
            <a data-toggle="modal" data-target="#rlpDescription"> 
            <!-- <a @click="RLP()" target="_blank"> -->
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
              </div>
            </a>
          </div>
        </template>

        <div class="col-md-6 mt-1" v-if="Number(grade) != 8 && Number(grade) != 12 && campusId != 69252">
            <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              </div>
            </a>
        </div>
        <div class="col-md-6 mt-1" v-if="Number(grade) != 2 && Number(grade) != 3 && Number(grade) != 8 && Number(grade) != 12 && campusId == 69252">
            <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              </div>
            </a>
        </div>

        <div v-if="APlusListOkullar() && APlusListPlatformVarmi().includes('hll')" class="col-md-6 mt-1" > 
          <a>
            <div :class="$style.divPlatform" @click="checkHll()">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            </div>
          </a>
        </div>        
        <div  v-else-if="AListOkullar() && AListPlatformVarmi().includes('hll') " class="col-md-6 mt-1"> 
          <a>
            <div :class="$style.divPlatform" @click="checkHll()">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            </div>
          </a>
        </div>

        <div class="col-md-6 mt-1" v-if="APlusListOkullar() && (APlusListPlatformVarmi().includes('nom'))">
            <a @click="nom()" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsOMatic]"></div>
              </div>
            </a>
        </div>
        <!-- <div class="col-md-6 mt-1" v-if="!APlusListOkullar() && ((Number(this.grade) >= 5 && Number(this.grade) <= 7) || findTeacGrade(5,7))">
            <a @click="nom()" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsOMatic]"></div>
              </div>
            </a>
        </div> -->
        <div class="col-md-6 mt-1" v-if="(APlusListOkullar() && (APlusListPlatformVarmi().includes('academejr'))) || (AListOkullar() && (AListPlatformVarmi().includes('academejr')))">
          <a @click="ACADEMEJR()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAcademeJR]"></div>
            </div>
          </a>
        </div>

        <div class="col-md-6 mt-1" v-if="(APlusListOkullar() && (APlusListPlatformVarmi().includes('espresso'))) || (AListOkullar() && (AListPlatformVarmi().includes('espresso')))">
          <a @click="ESPRESSO()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEspresso]"></div>
            </div>
          </a>
        </div>

        <div v-if="(APlusListOkullar() && (APlusListPlatformVarmi().includes('assessment'))) || 
        (AListOkullar() && (AListPlatformVarmi().includes('assessment'))) || (AkademiOkullar() && (AkademiPlatformVarmi().includes('assessment')))" class="col-md-6 mt-1">
          <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnlAssessment]"></div>
            </div>
          </a>
        </div>

        <div v-if="AkademiOkullar() && (AkademiPlatformVarmi().includes('hexa'))" class="col-md-6 mt-1">
          <a :href="`https://www.hexagonia.com.tr${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            </div>
          </a>
        </div> 

        <div  v-if="AkademiOkullar() && AkademiPlatformVarmi().includes('hll') " class="col-md-6 mt-1"> 
          <a>
            <div :class="$style.divPlatform" @click="checkHll()">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            </div>
          </a>
        </div>     
        

        <template v-if="this.level === 'İlkokul'">
          <div v-if="studentWthsCode.length > 0" class="col-md-12 mt-1">
            <a :href="`https://wths.uespublishing.com/#/?${studentWthsCode}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim]"></div>
              </div>
            </a>
          </div>
        </template>
        <template v-else-if="this.level === 'Ortaokul' && (Number(grade) < 8 && Number(grade) > 4)">
          <div v-if="studentWthsCode.length > 0" class="col-md-12 mt-1">
            <a :href="`https://wths.uespublishing.com/#/?${studentWthsCode}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim]"></div>
              </div>
            </a>
          </div>
        </template>
        <template v-else-if="this.level === 'Lise'">
          <div v-if="studentWthsCode.length > 0" class="col-md-6 mt-1">
            <a :href="`https://wths.uespublishing.com/#/?${studentWthsCode}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim]"></div>
              </div>
            </a>
          </div>
        </template>
        <template v-else>  
          <div v-if="studentWthsCode.length > 0" class="col-md-6 mt-1">
            <a :href="`https://wths.uespublishing.com/#/?${studentWthsCode}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAim]"></div>
              </div>
            </a>
          </div>
        </template>
        
      </template>
      <template v-else>
        
          <div class="col-md-6 mt-1" style="padding: 0px 30px;">
            <div :class="$style.divPlatform" @click="$router.push('/platforms')">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnContents]"></div>
            </div>
          </div>
          <div class="col-md-6 mt-1" style="padding: 0px 30px;" @click="query()" data-toggle="modal" data-target="#exampleModalCenter">
            <!-- <a :href="`https://www.uesportals.com/#/login/signin${querystingReporttext}`" target="_blank"> -->
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReports]"></div>
              </div>
            <!-- </a> -->
          </div>

          <div class="col-12 mt-1" style="padding: 0px 30px;" v-if="this.rlpUse != null">
            <a :href="`https://richmondlp.com/login`" target="blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
              </div>
            </a>
          </div>

          <div class="col-12 mt-1" style="padding: 0px 30px;">
            <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              </div>
            </a>
          </div>

          <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Select Your Report Platform</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  
                  <div class="col-md-12 mt-1">
                    <a v-if="roleType === 'teacher'" href="/#/assignment/report" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnl]"></div>
                      </div>
                    </a>
                  </div>
                  <div v-if="(APlusListOkullar() && APlusListPlatformVarmi().includes('hll')) || 
                  (AListOkullar() && AListPlatformVarmi().includes('hll')) || AkademiOkullar() && AkademiPlatformVarmi().includes('hll')" class="col-md-12 mt-1" > 
                    <a>
                      <div :class="$style.divPlatform" @click="checkHll()">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
                      </div>
                    </a>
                  </div>    
                  
                  <div v-if="(APlusListOkullar() && APlusListPlatformVarmi().includes('assessment')) || 
                  (AListOkullar() && AListPlatformVarmi().includes('assessment')) || (AkademiOkullar() && AkademiPlatformVarmi().includes('assessment'))" class="col-md-12 mt-1">
                    <a :href="`https://report.myeduassessment.com/#/login${querystingtext}`" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnlAssessment]"></div>
                      </div>
                    </a>
                  </div>

                  <div v-if="AkademiOkullar() && (AkademiPlatformVarmi().includes('hexa'))" class="col-md-12 mt-1">
                    <a :href="`https://www.uesportals.com/#/login/signin${querystingHexaReport}`" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
                      </div>
                    </a>
                  </div>

                  <div class="col-md-12 mt-1" v-if="(APlusListOkullar() && APlusListPlatformVarmi().includes('academejr')) || (AListOkullar() && AListPlatformVarmi().includes('academejr'))">
                    <a @click="ACADEMEJR()" target="_blank">
                      <div :class="$style.divPlatform">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAcademeJR]"></div>
                      </div>
                    </a>
                  </div>

                  <div class="col-md-12 mt-1" v-if="(APlusListOkullar() && APlusListPlatformVarmi().includes('nom')) || (AListOkullar() && AListPlatformVarmi().includes('nom'))">
                      <a @click="nom()" target="_blank">
                        <div :class="$style.divPlatform">
                          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsOMatic]"></div>
                        </div>
                      </a>
                  </div>

                  <div class="col-md-6 mt-1" v-if="(APlusListOkullar() && (APlusListPlatformVarmi().includes('espresso'))) || (AListOkullar() && (AListPlatformVarmi().includes('espresso')))">
                      <a :href="`https://app.discoveryeducation.com/`" target="_blank">
                      <div :class="$style.divPlatform">
                        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEspresso]"></div>
                      </div>
                    </a>
                  </div>

                  <!-- <div class="col-md-12" v-else-if="this.level === 'İlkokul'">
                    <div class="mt-1">
                      <a :href="`https://www.uesportals.com/#/login/signin${querystingKidzReport}`" target="_blank">
                        <div :class="$style.divPlatform">
                          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-12 mt-1" v-else-if="this.level === 'Lise'">
                    <a :href="`https://www.uesportals.com/#/login/signin${querystingFnlReport}`" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnl]"></div>
                      </div>
                    </a>
                  </div> -->
                  <!-- <div class="col-md-12 mt-1">
                    <a :href="`https://www.uesportals.com/#/login/signin${querystingFnlReport}`" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnl]"></div>
                      </div>
                    </a>
                  </div> -->
                  
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        
      </template>
    </div>
  <!-- </div> -->
    <div class="modal fade" id="rlpDescription" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Richmond Learning Platform Aktivasyon Adımları</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                <!-- <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank"> -->
                  <div :class="$style.divPlatform">
                    <template v-if="this.rlpCode != null && this.rlpCode != '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <b>"Kodu Kopyala"</b> butonuna basarak kitap kodunuzu kopyalayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kopyaladığınız kodu <b>"Please Enter a Code"</b> alanına yapıştırınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Kodu yapıştırdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">7.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>
                    <template v-else-if="this.rlpCode == '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <b>"Please Enter a Code"</b> alanına yazınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b>	Kodu yazdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>


                  </div>
                <!-- </a> -->
              </div>

              <div class="col-md-12 mt-1" v-if="this.rlpCode != null && this.rlpCode != '###'">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px" ref="code" v-on:focus="$event.target.select()" >{{rlpCode}}</p>
                        <input type="hidden" id="rlp-code" :value="rlpCode">
                      </div>
                      
                      <div class="col-4">                      
                        <button type="button" @click="copyCode()" class="btn btn-primary">Kodu Kopyala</button>
                      </div>
                    </div>                    
                  </div>
              </div>
              <div class="col-md-6 mt-1">
                <!-- <a data-toggle="modal" data-target="#rlpDescription"> -->
                  <a @click="RLP()" target="_blank" data-dismiss="modal">
                  
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode != null && this.rlpCode != '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode == '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { mapState } from 'vuex';
import HeaderCustom from '@/components/HeaderCustom';
import services from '@/services/user';
import schoolsArr from '../../../static/js/newSchools';
import CryptoJS from 'crypto-js';

import swal from 'sweetalert';


export default {
  name: 'platforms',
  data() {
    return {
      teacherGrades:[],
      rlpUse: null,
      rlpCode: null,
      grade: '',
      level: '',
      roleType: '',
      campusType: '',
      campusId : null,
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
      kwquerystingtextDemoLevel1: '',
      kwquerystingtextDemoLevel2: '',
      kwquerystingtextDemoLevel3: '',
      kwquerystingtextDemoLevel4: '',
      hexaquerystingtextDemoLevel5: '',
      hexaquerystingtextDemoLevel6: '',
      hexaquerystingtextDemoLevel7: '',
      hexaquerystingtextDemoLevel5de: '',
      hexaquerystingtextDemoLevel6de: '',
      hexaquerystingtextDemoLevel7de: '',
      karnevalstingtextDemoLevel2: '',
      karnevalstingtextDemoLevel3: '',
      karnevalstingtextDemoLevel4: '',
      kwquerystingtextDemoLevel1de: '',
      kwquerystingtextDemoLevel2de: '',
      kwquerystingtextDemoLevel3de: '',
      kwquerystingtextDemoLevel4de: '',
      hllDemoLevel1User: 'finalgrade1',
      hllDemoLevel2User: 'finalgrade2',
      hllDemoLevel3User: 'finalgrade3',
      hllDemoLevel4User: 'finalgrade4',
      hllDemoLevel5User: 'finalgrade5',
      hllDemoLevel6User: 'finalgrade6',
      hllDemoLevel7User: 'finalgrade7',
      hllDemoLevel1Class: '1-A',
      hllDemoLevel2Class: '2-A',
      hllDemoLevel3Class: '3-A',
      hllDemoLevel4Class: '4-A',
      hllDemoLevel5Class: '5-A',
      hllDemoLevel6Class: '6-A',
      hllDemoLevel7Class: '7-A',
      hllDemoPass:'123456',
      querystingHexaReport: '',
      querystingKidzReport: '',
      querystingFnlReport: '',
      studentWthsCode: '',
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  props: ['isHome', 'isStudent'],
  beforeMount() {
    this.setData();
    this.kwquerystingtextDemoLevel1 = `?username=${btoa('finaldemo1a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel2 = `?username=${btoa('finaldemo2a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel3 = `?username=${btoa('finaldemo3a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel4 = `?username=${btoa('finaldemo4a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel5 = `?username=${btoa('finaldemo5a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel6 = `?username=${btoa('finaldemo6a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel7 = `?username=${btoa('finaldemo7a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel5de = `?username=${btoa('final5a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel6de = `?username=${btoa('final6a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel7de = `?username=${btoa('final7a')}&password=${btoa('123456')}`;
    this.karnevalstingtextDemoLevel2 = `?username=${btoa('finaldemo2a')}&password=${btoa('123456')}`;
    this.karnevalstingtextDemoLevel3 = `?username=${btoa('finaldemo3a')}&password=${btoa('123456')}`;
    this.karnevalstingtextDemoLevel4 = `?username=${btoa('finaldemo4a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel1de = `?username=${btoa('final1a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel2de = `?username=${btoa('final2a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel3de = `?username=${btoa('final3a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel4de = `?username=${btoa('final4a')}&password=${btoa('123456')}`;
    // this.hexaquerystingtextDemoLevel5de2 = `?username=${btoa('final5a')}&password=${btoa('123456')}`;
    // this.hexaquerystingtextDemoLevel6de2 = `?username=${btoa('final6a')}&password=${btoa('123456')}`;
    // this.hexaquerystingtextDemoLevel7de2 = `?username=${btoa('final7a')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel1 = `?username=${btoa('finalgrade1')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel2 = `?username=${btoa('finalgrade2')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel3 = `?username=${btoa('finalgrade3')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel4 = `?username=${btoa('finalgrade4')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel5 = `?username=${btoa('finalgrade5')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel6 = `?username=${btoa('finalgrade6')}&password=${btoa('123456')}`;
    this.hllquerystingtextDemoLevel7 = `?username=${btoa('finalgrade7')}&password=${btoa('123456')}`;
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    copyCode(){
      let testingCodeToCopy = document.querySelector('#rlp-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      // try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
      //   alert('Testing code was copied ' + msg);
      // } catch (err) {
      //   alert('Oops, unable to copy');
      // }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === 'demo') return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    findTeacGrade(grade1, grade2){
        const grade = this.teacherGrades.filter((x) => (x >= grade1 && x <= grade2))
        if (grade.length > 0) return true; else return false;
    },
    async getZoomTokenStudent() {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      let payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'member',
          },
          features: {
            'screen-sharing': false,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: `${this.zoom[0].id}-${this.zoom[0].name}`,
        moderator: false,
        exp: Number(parsedTime) + 180,
      };
      if (this.main === 11837 || this.main === 11885) {
        payload = {
          context: {
            user: {
              avatar: '',
              name: fullName,
              email: this.userDetails.email,
              affiliation: 'member',
            },
            features: {
              'screen-sharing': true,
            },
          },
          aud: 'myeducams',
          iss: 'myeducams',
          sub: 'myeducams.com',
          room: `${this.zoom[0].id}-${this.zoom[0].name}`,
          moderator: false,
          exp: Number(parsedTime) + 180,
        };
      }
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${this.zoom[0].id}-${this.zoom[0].name}?jwt=${token}`, '_blank');
    },
    async getZoomTokenTeacher(zoomClass) {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      const payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'owner',
          },
          features: {
            'screen-sharing': true,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: zoomClass,
        moderator: true,
        exp: Number(parsedTime) + 180,
      };
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${zoomClass}?jwt=${token}`, '_blank');
    },
    async setData() {
      
      // console.log('userDetails',this.userDetails)
      if (this.userDetails) {
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.level = this.userDetails.organization.find(x => x.type === 'level') ? this.userDetails.organization.find(x => x.type === 'level').name : null;
        this.campusType = this.userDetails.organization.filter(x => x.type === 'campus')[0].school_type;
        this.campusId = this.userDetails.organization.filter(x => x.type === 'campus')[0].id;
        this.roleType = this.userDetails.role_type;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        this.rlpUse = this.userDetails.organization.find(x => x.type === 'campus').rlp_institute_code
        this.rlpCode = this.userDetails.rlp_code ? this.userDetails.rlp_code : null

        // this.campusId =1494
        // this.grade=9
        // console.log(1,this.APlusListPlatformVarmi())

        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }

        const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
        for (let key in teacherGrades){
          this.teacherGrades.push(teacherGrades[key].name.split("-")[0])
        }

        const campus = this.userDetails.organization.find(x => x.type === 'campus');
        const school = schoolsArr.find(x => x.school_id === campus.id);
        if (school) {
          // const aimData = school.aim_codes.find(x => x.grade === Number(this.grade));
          const res = await axios.get('https://www.hllreport.com/api/finalAims?school_id='+ campus.id + '&grade=' + Number(this.grade));
          const aimCode = atob(res.data).trim();
          this.studentWthsCode = aimCode;
          // console.log('res', aimCode);
          // if (aimData.query === 'grade') {
          //   const codeData = aimData.codes.find(x => x.title === String(this.grade));
          //   if (codeData) {
          //     this.studentWthsCode = codeData.code;
          //   }
          // }
          // this.studentWthsCode = `?code=${btoa(this.studentWthsCode)}`;
          this.studentWthsCode = `?code=${this.studentWthsCode}`;
        }
      }
      
    },
    goTeacherLink(id) {
      this.$router.push({
        name: 'teachersResources',
        params: {
          id,
        },
      });
    },
    query() {
      this.querystingHexaReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('4')}`;
      this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
      this.querystingFnlReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('7')}`;
      this.querystingKarnReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('5')}`;
    },
    
    RLP() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;

          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
        }
          // console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    ACADEMEJR() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;

          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
        }
          // console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/efs/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    ESPRESSO() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "EDcseSrkMx%Nvug6VptLyR85Pq.KZ4J-Yd7QbA9H2F!XBGCa3jWzTf";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;

          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
        }
          // console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/espresso/?sso=' + btoa(ciphertext));

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    async checkHll(demoUser, demoPass, demoClass) {
      try {
        this.ready = false;
        const userDetails = await this.userDetails;
        let userId
        if(userDetails.role_type === "student"){
          userId = userDetails.user_id;

        } else{
          userId = '2023'+ userDetails.user_id;
        }

        const firstName = userDetails.first_name;
        const lastName = userDetails.last_name;
        // const className = userDetails.organization.find(x => x.type === 'grade').name;
        const userType = userDetails.role_type;
        // const campusId = userDetails.organization.find(y => y.type === 'campus').id;
        let key = userDetails.organization.find(y => y.type === 'campus').hll_company_code;
        // console.log('key', key);
        // const key = obj.find(x => x.schoolId === campusId).companyKey;

        if(userDetails.role_type === "student"){
          const className = encodeURIComponent(userDetails.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.role_type === "teacher"){
          const classes = [];
          await userDetails.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });

          if (demoUser){
            key = 'TZ9f1xUWLbK6YVCMS3eT4Q=='
            window.go_hll(userId, userId, firstName, lastName, demoClass, userType, key);
          } else{
            window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
          }
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    // nom() {
    //     window.open('https://app.newsomatic.net/login', '_blank');
    // },

    nom() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.school_name =  this.userDetails.organization.find(x => x.type === 'main').name;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/nom/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    APlusListOkullar(){

      const okulId =[
        1872,1964,556,1067,1276,655,685,739,71947,1185,1211,1399,1632,742,17386,1004,1051,72819,1116,1157,17387,17413,1601,1689,84096,2169,
        1716,1727,17411,2269,2319,17398,5896,1743,1796,2625,17372,72823,5307,5454,5580,10057,1991,71959,69252,87354,2169,1590,17395,17388]

        if(okulId.includes(this.campusId)){
          return true
        } else return false

    },

    APlusListPlatformVarmi(){
      let platform = []
      const nom_5_6_7_CampusId = [
        739,2169,655,685,1211,17386,1004,1716,2269,2319,556,1067,1276,71947,1632,742,1051,72819,1116,17387,17413,1590,1689,84096,1727,
        17411,5896,1743,1796,2625,17372,72823,5307,5454,10057,1964,1399,1872,199,71959,69252,87354,1185,1601,5580,17395,1157,1991
      ]

      const hll_3_4_CampusId = [
        739,655,685,1211,17386,1004,1716,2269,2319,556,1067,1276,71947,1632,742,1051,72819,1116,17387,17413,1590,1689,84096,1727,17411,
        5896,1743,1796,2625,17372,72823,5307,5454,10057,1964,1399,1872,1991,71959,69252,87354,1185,1601,5580,1157, 2169
      ]
      

      const akademejr_1_2_CampusId = [
        655,685,1211,17386,1004,1716,2269,2319,556,1067,1276,71947,1632,742,1051,72819,1116,17387,17413,1590,1689,84096,1727,17411,
        5896,1743,1796,2625,17372,72823,5307,5454,10057,87354,1185,1601,5580,
      ] // 1 ve 2 ler

      const akademejr_2_CampusId = [739,1964,1399,1872,1991,71959,69252,1157,2169] // sadece 2 ler
      
      const espresso_56_1_CampusId = [1964,1399,1872,1991,71959,69252]// 5-6 yaş ve 1 ler
      const espresso_1_CampusId = [739,2169] // sadece 1 ler
      const espresso_56_CampusId = [87354,1185,1601,5580] // sadece 5-6 yaş
      const espresso_6_1_CampusId = [1157] // sadece 6 yaş ve 1
      const assessment_9_10_CampusId = [17388] // sadece 6 yaş

      const assessment_2den10_CampusId = [ // 8 hariö hepsi
        2169,556,1067,1276,71947,1632,742,1051,72819,1116,17387,17413,1590,1689,84096,1727,17411,5896,1743,
        1796,2625,17372,72823,5307,5454,10057,1399,1872,1991,71959,69252,1185,1601,5580,1157,48683, 17395,
        655,685,1211,17386,1004,1716,2269,2319,739,1964,87354]

      if(hll_3_4_CampusId.includes(this.campusId) &&  (this.grade == 3 || this.grade == 4 || this.findTeacGrade(3, 4))){
        platform.push('hll')
      }

      if(nom_5_6_7_CampusId.includes(this.campusId) &&  ((this.grade >= 5 && this.grade <= 7) || this.findTeacGrade(5, 7))){
        platform.push('nom')
      }

      if(akademejr_2_CampusId.includes(this.campusId) && (this.grade == 2 || this.findTeacGrade(2, 2))){
        platform.push('academejr')
      }else
      if(akademejr_1_2_CampusId.includes(this.campusId) &&  (this.grade == 1 || this.grade == 2 || this.findTeacGrade(1, 2))){
        platform.push('academejr')
      }

      if(espresso_6_1_CampusId.includes(this.campusId) && (this.grade == 106 || this.grade == 1 || this.findTeacGrade(106, 106) || this.findTeacGrade(1, 1))){
        platform.push('espresso')
      }else
      if(espresso_56_CampusId.includes(this.campusId) && (this.grade == 105 || this.grade == 106 || this.findTeacGrade(105, 106))){
        platform.push('espresso')
      }else
      if(espresso_1_CampusId.includes(this.campusId) && (this.grade == 1 || this.findTeacGrade(1, 1))){
        platform.push('espresso')
      }else
      if(espresso_56_1_CampusId.includes(this.campusId) &&  (this.grade == 1 || this.grade == 105 || this.grade == 106 || this.findTeacGrade(1, 1)|| this.findTeacGrade(105, 106))){
        platform.push('espresso')
      }

      if(assessment_2den10_CampusId.includes(this.campusId) &&  ((this.grade >= 2 && this.grade <= 10 && this.grade != 8) || this.findTeacGrade(2, 7) || this.findTeacGrade(9, 10))){
        platform.push('assessment')
      }
      if(assessment_9_10_CampusId.includes(this.campusId) &&  ((this.grade >= 9 && this.grade <= 10) || this.findTeacGrade(9, 10))){
        platform.push('assessment')
      }

      return platform
    },

    AListOkullar(){
      const okulId =[1494,1099,4726,6173,17375,17378,95390,87278,44899,635,1904,2113,2092,4988,87323,17403]
      

        if(okulId.includes(this.campusId)){
          return true
          
        } else {
          return false
        }

    },

    AListPlatformVarmi(){
      let platform = []
      const hll_1_to_7_CampusId = [1494,1099,4726,6173,17375,17378,44899,635,1904,2113,2092,87323,17403]
      const hll_5_6_7_CampusId = [87278]
      const hll_3_4_5_6_7_CampusId = [95390]

      const assessment_2den10_CampusId = [1494,1099,4726,6173,17375,17378,95390,87278,44899,635,1904,2113,2092,4988,87323,17403]
      
      const akademejr_2_CampusId = [95390] // sadece 2 ler
      
      const espresso_6_1_CampusId = [95390] // 6 yaş ve 1 ler
      const espresso_56_CampusId = [17375] // sadece 5-6 yaş


      if(hll_5_6_7_CampusId.includes(this.campusId) &&  ((this.grade >= 5 && this.grade <= 7) || this.findTeacGrade(5, 7))){
        platform.push('hll')
      }else 
      if(hll_3_4_5_6_7_CampusId.includes(this.campusId) &&  ((this.grade >= 3 && this.grade <= 7) || this.findTeacGrade(3, 7))){
        platform.push('hll')
      }else
      if(hll_1_to_7_CampusId.includes(this.campusId) &&  ((this.grade >= 1 && this.grade <= 7) || this.findTeacGrade(1, 7))){
        platform.push('hll')
      }

      // if(assessment_9_10_CampusId.includes(this.campusId) &&  (this.grade == 9 || this.grade == 10 || this.findTeacGrade(9, 10))){
      //   platform.push('assessment')
      // }

      if(assessment_2den10_CampusId.includes(this.campusId) &&  ((this.grade >= 2 && this.grade <= 10 && this.grade != 8) || this.findTeacGrade(2, 7) || this.findTeacGrade(9, 10))){
        platform.push('assessment')
      }

      if(akademejr_2_CampusId.includes(this.campusId) && (this.grade == 2 || this.findTeacGrade(2, 2))){
        platform.push('academejr')
      }

      if(espresso_56_CampusId.includes(this.campusId) && (this.grade == 105 || this.grade == 106 || this.findTeacGrade(105, 106))){
        platform.push('espresso')
      }else
      if(espresso_6_1_CampusId.includes(this.campusId) && (this.grade == 1 || this.grade == 106 || this.findTeacGrade(1, 1) || this.findTeacGrade(106, 106) )){
        platform.push('espresso')
      }     

      return platform
    },

    AkademiOkullar(){
      const okulId =[
        57217,9845,4030,87142,87147,87353,87256,87250,87251,4495,4508,87257,87253,87258,4294,87254,87255,87261,87265,4585,4572,7332,4667,76388,47341,87345,
        87268,47815,87269,87270,87271,87272,45186,87357,87277,68491,87275,87278,5081,87290,48104,993,47357,87284,5296,87285,87287,76542,1768,7669,84774,
        626,8039,87288,5765,17397,5087,1150,87300,17404,47272,10157,1857,87296,87304,87303,87279,87280,5132,7537,5387,87310,87312,87316,87315,87324,87329,
        66279,87325,87326,6120]
        if(okulId.includes(this.campusId)){
          return true
        } else {
          return false
        }

    },

    AkademiPlatformVarmi(){
      let platform = []
      const hexa_5_6_7_CampusId = [9845]
      const hexa_5_6_7_9_10_CampusId = [
        87353,87256,87251,4495,4508,87258,4294,76388,47341,87345,87268,47815,45186,87275,993,87284,5296,87285,87287,76542,5765,17397,87300,
        87280,5132,7537,5387,87310,87326
      ]
      const hexa_9_10_CampusId = [
        57217,4030,87147,87250,87257,87253,87254,87255,87261,87265,4585,4572,7332,4667,87269,87270,87271,87272,87357,87277,68491,87278,5081,
        87290,48104,47357,1768,7669,84774,8039,87288,5087,1150,17404,47272,10157,1857,87296,87304,87303,87279,87312,87316,87315,87324,87329,
        66279,87325,6120
      ]

      const assessment_2den10_CampusId = [
        87353,87256,87251,4495,4508,87258,4294,76388,47341,87345,87268,47815,45186,87275,993,87284,5296,87285,87287,76542,5765,17397,87300,
        87280,5132,7537,5387,87310,87326,9845,
        87142,626,57217,4030,87147,87250,87257,87253,87254,87255,87261,87265,4585,4572,7332,4667,87269,87270,87271,87272,87357,87277,68491,
        87278,5081,87290,48104,47357,1768,7669,84774,8039,87288,5087,1150,17404,47272,10157,1857,87296,87304,87303,87279,87312,87316,87315,
        87324,87329,66279,87325,6120
      ]
  
      const hll_5_6_7_CampusId = [5132]

      if(hexa_5_6_7_CampusId.includes(this.campusId) &&  ((this.grade >= 5 && this.grade <= 7) || this.findTeacGrade(5, 7))){
        platform.push('hexa')
      } 
      if(hexa_5_6_7_9_10_CampusId.includes(this.campusId) &&  ((this.grade >= 5 && this.grade <= 10 && this.grade !=8) || this.findTeacGrade(3, 7) || this.findTeacGrade(9, 10))){
        platform.push('hexa')
      }
      if(hexa_9_10_CampusId.includes(this.campusId) &&  ((this.grade == 9 || this.grade == 10) || this.findTeacGrade(9, 10))){
        platform.push('hexa')
      }
      if(assessment_2den10_CampusId.includes(this.campusId) &&  ((this.grade >= 2 && this.grade <= 10 && this.grade != 8) || this.findTeacGrade(2, 7) || this.findTeacGrade(9, 10))){
        platform.push('assessment')
      }
      if(hll_5_6_7_CampusId.includes(this.campusId) &&  ((this.grade >= 5 && this.grade <= 7) || this.findTeacGrade(5, 7))){
        platform.push('hll')
      } 
   

      return platform
    },

  },
  components: {
    HeaderCustom,
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
